import React from "react";
// import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bannermain from '../Products/ex electrical/banner.png'
import '../About.css'
import amf from '../Products/ex electrical/amfupdate.webp'
import apfc from '../Products/ex electrical/APFC Panel.jpg'
// import effici from '../Products/ex electrical/EfficiencyRedefined.webp'
import mcc from '../Products/ex electrical/mcc-panel.jpg'
import ngr from '../Products/ex electrical/ngrupdate.webp'
import pcc from '../Products/ex electrical/pcc-panels.png'
import vfd from '../Products/ex electrical/VFD Panel.jpg'
import synchro from '../Products/ex electrical/Synchronization Panel.webp'
import ready from '../Products/ex electrical/iot ready.webp'
import plc from '../Products/ex electrical/plc3.jpg'





const PanelExElectrical = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);


  useEffect(() => {
    AOS.init(); // Initialize the AOS library
  }, []);

  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>


      <header className="" style={{ marginTop: "1rem" }}>
        <img src={bannermain} alt="Logo" className='aboutimg' />

        <div className="container">
          <div className="row">
            <div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
              <p>
                <span style={{ color: "rgb(255,102,0)" }}></span>Welcome to a world where power meets precision, where innovation sparks seamless operations  –<span style={{ color: "orange" }}> welcome to Thetavega's revolutionary panel products.</span> <br/>
                <br/>ThetaVega revolutionizes electrical control with an exceptional range of panel products, from dynamic PCC and MCC panels to precision-driven APFC and AMF panels, embracing efficiency and innovation. Our field panels put control at your fingertips, while VFD and PLC panels offer precision and automation.<br/><br/>
                Experience stability with our Synchronization and NGR panels for seamless power synchronization and safety. Elevate your electrical control to new heights with ThetaVega's solutions, where power meets precision, and innovation sparks seamless operations.<br/>
              </p>

            </div>
          </div>
        </div>
      </header>




      {/* --------------------------------------SECTION----------------------------------------------------- */}
      <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
      ><b>Experience the Future of Control with IoT-Ready Panels</b></h1>
      <div className="container dataparts">
        <div className="row paragraph1 mt-5">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={ready} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Harness Connectivity, Data Insights, and Remote Monitoring</b></span>
            </h3>
            <p>
              At Thetavega, we're not just about panels; we're about redefining control for the digital age. Our exceptional lineup of IoT-ready panels empowers industries with the next level of innovation, seamlessly integrating connectivity, data exchange, and remote monitoring.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1" >

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>PCC Panels: Powered by Connectivity </b></span>
            </h3>
            <p>
              Experience power distribution and control like never before. Our Power Control Center (PCC) panels go beyond traditional control, enabling real-time data exchange and remote access for unparalleled command.</p>
            <p style={{ color: "rgb(255,102,0" }}> Features </p>

            <ol>
              <li>Real-time Monitoring</li>
              <li>Remote Access for Control</li>
              <li>Predictive Maintenance Insights</li>
              <li>Overload Protection</li>
              <li>Voltage and Current Monitoring</li>
            </ol>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={pcc} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={mcc} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>MCC Panels: Precision Meets Intelligences</b></span>
            </h3>
            <p>
              Welcome to the era of precision. With our IoT-ready Motor Control Center (MCC) panels, intelligence takes the lead. Monitor performance, optimize efficiency, and detect issues before they arise.
            </p><p style={{ color: "rgb(255,102,0" }}> Features </p>
            <ol>
              <li>Performance Analytics</li>
              <li>Efficiency Optimization</li>
              <li>Remote Issue Detection</li>
              <li>Motor Status Monitoring</li>
              <li>Fault Diagnostics</li>
            </ol>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>APFC Panels: Efficiency Elevated</b></span>
            </h3>
            <p>
              Efficiency just got smarter. Our Automatic Power Factor Correction (APFC) panels integrate IoT capabilities to monitor, analyse, and optimize power factor with unparalleled accuracy

            </p><p style={{ color: "rgb(255,102,0" }}> Features </p>
            <ol>
              <li>Power Factor Analytics</li>
              <li>Energy Usage Insights</li>
              <li>Optimal Power Factor Control</li>
              <li>Harmonic Filtering</li>
              <li>Automatic Capacitor Bank Control</li>
            </ol>

          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={apfc} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={amf} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>AMF Panels: Uninterrupted Insights</b></span>
            </h3>
            <p>
              Stay ahead of disruptions. Our Auto Main Failure (AMF) panels with IoT-readiness provide remote insights, ensuring uninterrupted power supply and swift response.

            </p><p style={{ color: "rgb(255,102,0" }}> Features </p>
            <ol>
              <li>Remote Status Monitoring</li>
              <li>Swift Response Capabilities</li>
              <li>Data-Driven Power Transitions</li>
              <li>Generator Status Monitoring</li>
              <li>Load Shedding Control</li>
            </ol>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Field Panels: Control Redefined</b></span>
            </h3>
            <p>
              Empower your team with real-time insights. Thetavega Field Panels embrace IoT to put control and monitoring at your fingertips, regardless of your location.

            </p><p style={{ color: "rgb(255,102,0" }}> Features </p>
            <ol>
              <li>Real-time Data Insights</li>
              <li>Remote Monitoring and Adjustment</li>
              <li>Enhanced Decision-Making</li>
              <li>Operator Guidance System Integration</li>
              <li>Remote Firmware Updates</li>
            </ol>

          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={pcc} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={vfd} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <br />
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>VFD Panels: Precision Evolved</b></span>
            </h3>
            <p>
              IoT transforms precision. Our Variable Frequency Drive (VFD) panels offer remote adjustments, optimization, and real-time insights for dynamic operations.

            </p><p style={{ color: "rgb(255,102,0" }}> Features </p>
            <ol>
              <li>Remote Adjustments</li>
              <li>Dynamic Optimization</li>
              <li>Real-time Performance Tracking</li>
              <li>Multi-Speed Control</li>
              <li>Torque Control</li>
            </ol>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>PLC Panels: Intelligence in Action</b></span>
            </h3>
            <p>
              Unleash the power of IoT-driven control. Our Programmable Logic Controller (PLC) panels redefine automation, enabling data-driven decisions for enhanced outcomes.
            </p><p style={{ color: "rgb(255,102,0" }}> Features </p>
            <ol>
              <li>Data-Driven Automation</li>
              <li>Remote Programming and Control</li>
              <li>Performance Insights</li>
              <li>Process Sequencing</li>
              <li>Data Logging</li>
            </ol>

          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={plc} alt="loading..." style={{ height: "25rem", width: '90%' }} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={synchro} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <br />
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Synchronization Panels: Data-Driven Harmony</b></span>
            </h3>
            <p>
              Harmonize power like never before. Our IoT-ready Synchronization Panels ensure seamless synchronization, with data insights for informed decisions. </p><p style={{ color: "rgb(255,102,0" }}> Features </p>

            <ol>
              <li>Seamless Synchronization</li>
              <li>Real-time Data Insights</li>
              <li>Informed Decision-Making</li>
              <li>Load Balancing</li>
              <li>Frequency Synchronization</li>
            </ol>



          </div>
        </div>
      </div>


      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)", height: "400px" }}><b>NGR Panels: Safety Reinvented</b></span>
            </h3>
            <p>
              Safety meets smart. Our Neutral Grounding Resistor (NGR) panels offer remote monitoring and insights, prioritizing safety through connected control. </p><p style={{ color: "rgb(255,102,0" }}> Features </p>

            <ol>
              <li>Remote Safety Monitoring</li>
              <li>Connected Control</li>
              <li>Rapid Response to Safety Concerns</li>
              <li>Ground Fault Detection</li>
              <li>Alarm Integration</li>
            </ol>

          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={ngr} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
        </div>
      </div>






      <div className="container">

        <h3 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
        ><b>Discover the Unparalleled Advantages of Thetavega's IoT-Ready Panels</b></h3>
        <p className='text-center' data-aos="fade-down"
        > Elevate Control, Enhance Efficiency, and Redefine Industry Standards</p>
        <div className="container">
          <div className="row  paragraph1">
            <div className="col-md-11 " data-aos="zoom-in-left" style={{ margin: "auto" }}>
              <p>
                When it comes to electrical panels, Thetavega stands at the forefront of innovation. Our IoT-ready panels aren't just products; they're solutions that shape the future of control. Here's why our panels are the ultimate choice for your next project:

              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container ">
        <div className="row mt-4">
          <div className="col-md-2" style={{ height: '20rem' }} >

            <div >
              <h5 className="text-center" style={{color:"rgb(255,102,0)"}}><b>Exceptional Connectivity</b></h5>
              <p class="text-center">Seamlessly connect and control your systems from anywhere, at any time. Our IoT-ready panels provide real-time access, empowering you with total command.</p>
              {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
            </div>

          </div>
          <div className="col-md-2" style={{  height: '20rem' }}>

            <div style={{ padding: "5px" }}>
              <h5 class="text-center" style={{color:"rgb(255,102,0)"}}><b>Predictive Insights</b></h5>
              <p class="text-center">Stay steps ahead of disruptions with predictive maintenance insights. Our panels offer real-time data on performance, enabling you to optimize before issues arise.</p>
              {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}

            </div>
          </div>
          <div className="col-md-2">

            <div style={{ height: '20rem' }}>
              <h5 class="text-center" style={{color:"rgb(255,102,0)"}}><b>Efficiency Redefined</b></h5>
              <p class="text-center">Harness the power of data to optimize efficiency. Our panels offer insights into energy usage, power factor, and more, paving the way for unprecedented savings.</p>
              {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
            </div>

          </div>
          <div className="col-md-2" style={{ height: '20rem' }}>

            <div >
              <h5 class="text-center" style={{color:"rgb(255,102,0)"}}><b>Precision Control</b></h5>
              <p class="text-center">With remote adjustments, dynamic optimization, and data-driven decision-making, our panels bring precision to a new level, enhancing your operations.</p>
              {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
            </div>

          </div>
          <div className="col-md-2">

            <div style={{ height: '20rem' }}>
              <h5 class="text-center" style={{color:"rgb(255,102,0)"}}><b>Safety at the Core</b></h5>
              <p class="text-center">Safety is paramount. Our IoT-ready panels offer remote safety monitoring, ground fault detection, and rapid response features, prioritizing protection.<br/><br/>
             
              </p>
              {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
          </div>
            </div>
              <div className="col-md-11 " data-aos="zoom-in-left" style={{ margin: "auto" }}>
              <p>
              From manuufactturing floors to data centres, from energy production to complex processes, Thetavega's IOT-ready panels are the foundation of seamless control, intelligent insights, and industry-leading safety. Join us in embracing the future of control. Connect with Thetavega to unlock the unmatched potential of IOT-ready panels and experience power, efficiency and control like never before.

              </p>
            </div>

        </div>
      </div>


    </div>
  );
};


export default PanelExElectrical
