import React from 'react'
import "./About.css"
import logo1 from "./ABOUT/Innovations.png"
import logo2 from "./ABOUT/assemblyLine.svg"
import logo3 from "./ABOUT/dataquality copy.svg"
import logo4 from "./ABOUT/productionPlanning copy.svg"
import logo5 from "./ABOUT/monitoring copy.svg"
import logo6 from "./ABOUT/quality copy.svg"
import logo7 from "./ABOUT/machineVision copy.svg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

 

const Innovations = () => {
    useEffect(() => {
        AOS.init(); // Initialize the AOS library
      }, []);
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);
    return (
        <header className="" style={{ overflow: "hidden" }}>
            <div className='container-fluid m-0  p-0'>
                <img src={logo1} alt="Logo" className='aboutimg' />
                <div className='row about-containt'>
                    <div className='col-md-11 mt-4 text-justify'>
                        <p> At Thetavega, we symbolize a culture of dynamic innovation that infuses every side of our work – whether it's in service of our esteemed clients, dedicated employees, valued investors, or the wider community. Our core focus revolves around crafting and delivering Smart Automation, Digitization, and Traceability Solutions that redefine industry standards.
                        </p>
                    </div>
                    <div className='encompass mt-5' data-aos="fade-down">
                        <h1 className='text-center'><span style={{ color: "rgb(255,102,6)", marginLeft: "0rem"}}><b>Our Innovations Encompass:</b></span></h1>
                    </div>
                    <div className="coreCard">
                        <div className='row '>
                            <div className='col-md-3 p3 text-center' data-aos="zoom-in">
                                <div className='cardCore'>
                                    <img src={logo2} alt="Logo" style={{ padding: "1rem", width: "40%" }} />
                                    <h6 style={{ color: "  " }}><b>Assembly Lines & Material Handling Systems</b></h6>
                                    <p>Revolutionizing manufacturing processes with intelligent assembly lines and efficient material handling systems.</p>
                                </div>
                            </div>
                            <div className='col-md-3 p3 text-center' data-aos="zoom-in">
                                <div className='cardCore'>
                                    <img src={logo3} alt="Logo" style={{ padding: "1rem", width: "40%" }} />
                                    <h6 style={{ color: "  " }}><b>Part Traceability & Quality Data Management</b></h6>
                                    <p>Innovative Track and Trace systems that ensure part traceability and seamless quality data management.</p>
                                </div>
                            </div>
                            <div className='col-md-3 p3 text-center' data-aos="zoom-in">
                                <div className='cardCore'>
                                    <img src={logo4} alt="Logo" style={{ padding: "1rem", width: "40%" }} />
                                    <h6 style={{ color: "  " }}><b>.   Production Planning, Scheduling, Monitoring and controlling</b></h6>
                                    <p>Elevating production efficiency through innovative Planning, Proper Scheduling, Monitoring, and Control Systems.</p>
                                </div>
                            </div>
                        </div>
                        <div className='row '>
                            <div className='col-md-3 p3 text-center' data-aos="zoom-in">
                                <div className='cardCore'>
                                    <img src={logo5} alt="Logo" style={{ padding: "1rem", width: "40%" }} />
                                    <h6 style={{ color: "  " }}><b>Remote Monitoring, Data Logging & Control</b></h6>
                                    <p>Harnessing Remote Monitoring Capabilities and Data Logging for optimized operations.</p>
                                </div>
                            </div>
                            <div className='col-md-3 p3 text-center' data-aos="zoom-in">
                                <div className='cardCore'>
                                    <img src={logo6} alt="Logo" style={{ padding: "1rem", width: "40%" }} />
                                    <h6 style={{ color: "  " }}><b>Quality Testing & Assurance</b></h6>
                                    <p>Transforming Quality Testing and Assurance with advanced systems for perfect products.</p>
                                </div>
                            </div>
                            <div className='col-md-3 p3 text-center' data-aos="zoom-in">
                                <div className='cardCore'>
                                    <img src={logo7} alt="Logo" style={{ padding: "1rem", width: "40%" }} />
                                    <h6 style={{ color: "  " }}><b>Machine Vision and Sensors</b></h6>
                                    <p>Leveraging Machine Vision and cutting-edge Sensors to unlock new dimensions of insights.</p>
                                </div>
                            </div>
                            <p className='col-md-11 text-justify' data-aos="zoom-in"> These solutions seamlessly integrate with our thought leadership, showcasing our expertise in both solution definition and delivery. But our commitment to innovation goes beyond technology, it extends to how we operate our business, manage our processes, and foster leadership. It's about building a future where technology and humanity converge to create something truly exceptional.<br/><br/>
                    
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Innovations
