import React from 'react';
import "../About.css";
import { Link, NavLink } from 'react-router-dom';
import logo1 from '../TRACK N TRACE/TRACK N TRACE/BANNER.png';
import logo2 from '../TRACK N TRACE/TRACK N TRACE/Solution-Design-Planning.avif';
import logo3 from '../TRACK N TRACE/TRACK N TRACE/Hardware-software-Implementation.png';
import logo4 from '../TRACK N TRACE/TRACK N TRACE/Integration-Services.avif';
import logo5 from '../TRACK N TRACE/TRACK N TRACE/Data-Analytics-Reporting.avif';
import logo6 from '../TRACK N TRACE/TRACK N TRACE/Maintenance-Support.avif';
import 'animate.css';
import { useEffect } from 'react';
import AOS from 'aos';


const Trace = () => {
	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);


	return (
		<div className="container-fluid" style={{ overflow: "hidden" }}>
			<div className='container-fluid m-0  p-0'>
				<div className="bg0">
					<p className="col-md-12" >
						<NavLink to="/" className="navbutton1"> Dashboard </NavLink>/ <NavLink to="#" className="navbutton2"> track n trace </NavLink>
					</p>
				</div>
				<img src={logo1} alt="Logo" className='aboutimg mt-0 animate__animated animate__bounceInLeft' />

				{/* --------------------------------------SECTION----------------------------------------------------- */}
				<div className="container dataparts about-containt">
					<div className="row paragraph1 mt-5">

						<div className="col-md-5" data-aos="zoom-in-left">
							<img src={logo2} alt="loading..." />
						</div>
						<div className="col-md-5" data-aos="zoom-in-left">
							<h3 className="b11">
								<span style={{ color: "rgb(255,102,0)" }}><b>Solution Design and Planning </b></span>
							</h3>
							<p>
								We offer Traceability solution design and planning services to help businesses design and plan their implementation of traceability systems into Manufacturing Operations, including defining requirements, selecting technologies, and identifying integration strategies.
							</p>
						</div>
					</div>
				</div>

				<div className="container about-containt">
					<div className="row  paragraph1">

						<div className="col-md-6 mt-5" data-aos="zoom-in-right">
							<h3 className="b11">
								<span style={{ color: "rgb(255,102,0)" }}><b>Hardware and Software Implementation</b></span>
							</h3>
							<p>
								Our Hardware and software implementation services help businesses implement and configure the necessary hardware and software components for their traceability systems, including Barcoding, RFID, Bluetooth, Laser technologies, Data collection devices like PLC, Embedded Controllers, IOT Gateways, and software applications Designing Development & Implementation.
							</p>
						</div>
						<div className="col-md-4" data-aos="zoom-in-right">
							<img src={logo3} alt="loading..."  />
						</div>
					</div>
				</div>

				<div className="container about-containt">
					<div className="row paragraph1">

						<div className="col-md-5" data-aos="zoom-in-left">
							<img src={logo4} alt="loading..."  />
						</div>
						<div className="col-md-5 mt-5" data-aos="zoom-in-left">
							<h3 className="b11">
								<span style={{ color: "rgb(255,102,0)" }}><b>Integration Services</b></span>
							</h3>
							<p>
								Our Integration services help businesses integrate their traceability systems with other enterprise systems, such as MES or ERP systems, to create a seamless and integrated manufacturing environment.
							</p>
						</div>
					</div>
				</div>

				<div className="container about-containt">
					<div className="row  paragraph1">

						<div className="col-md-5" data-aos="zoom-in-right">
							<h3 className="b11">
								<span style={{ color: "rgb(255,102,0)" }}><b>Data Analytics and Reporting</b></span>
							</h3>
							<p>
								Our data analytics and reporting services help businesses analyse and visualize the data generated by their traceability systems, providing real-time visibility and insight into manufacturing operations, enabling better decision-making and improved efficiency.
							</p>
						</div>
						<div className="col-md-5" data-aos="zoom-in-right">
							<img src={logo5} alt="loading..."  />
						</div>
					</div>
				</div>
				<div className="container about-containt">
					<div className="row paragraph1">

						<div className="col-md-5" data-aos="zoom-in-left">
							<img src={logo6} alt="loading..."  />
						</div>
						<div className="col-md-5 mt-5" data-aos="zoom-in-left">
							<h3 className="b11">
								<span style={{ color: "rgb(255,102,0)" }}><b>Maintenance and Support</b></span>
							</h3>
							<p>
							Our Maintenance and support services help businesses maintain and support their traceability systems, including providing training, resolving issues, and ensuring ongoing system performance.
							</p>
						</div>
					</div>
				</div>
				





				<div className="container about-containt">
					<div className="row  paragraph1">
						<div className="col-md-10 mt-5 " data-aos="zoom-in-up">
							<p>Collectively, we provide businesses with the tools and expertise they need to implement and manage traceability systems that enable them to improve quality control, increase productivity, reduce costs, and ensure compliance with regulartory requirements in the manufacturing process.<Link to="/contact"  > Get in touch with us for more Details.</Link>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Trace;
