import React from "react";
import "./IOT.css";
import { NavLink } from 'react-router-dom';
import logo1 from "../IOT/banner.png";
import logo2 from "../IOT/1. Digital_Transformation_Consultancy.png";
import logo3 from "../IOT/management.jpg";
import logo4 from "../IOT/analytics.webp";
import logo5 from "../IOT/Cyber_Security.png";
import logo6 from "../IOT/AI_ML_Integration.avif";
import logo7 from "../IOT/IOT_INtegration.svg";
import logo8 from "../IOT/platform-development.png";
import logo from "../img3/IOT.png"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';



const IOT = () => {
  useEffect(() => {
    AOS.init(); // Initialize the AOS library
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);
  return (
    <div className="container-fluid" style={{overflow:"hidden"}}>
      <div className="bg0">
        <p className="col-md-12" >
          <NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Internet Of Things </NavLink>
        </p>
      </div>

      <header className="">
        <img src={logo1} alt="Logo" className='aboutimg mt-0' />
        
        <div className="container">
          <div className="row">
            <div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
              <p>
                <span style={{ color: "rgb(255,102,0)" }}>Internet of Things (IoT) </span> refers to the interconnected network of Physical Devices, Machines, Appliances, and other objects that are embedded with Sensors, Software, and Network Connectivity to collect and exchange data. We Offer various IoT services that businesses can leverage to improve their operations and create new revenue streams. Here are some of our common offerings for IoT Systems:
              </p>
            </div>
          </div>
        </div>
      </header>
      {/* --------------------------------------SECTION----------------------------------------------------- */}
      <div className="container dataparts">
        <div className="row paragraph1 mt-5">

          <div className="col-md-5" data-aos="zoom-in-left">
            <img src={logo2} alt="loading..." />
          </div>
          <div className="col-md-5" data-aos="zoom-in-left">
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Consultancy</b></span>
            </h3>
            <p>
            We provide strategic guidance to businesses looking to implement IoT solutions. we help identify the right technologies and solutions that can improve business processes and create new opportunities.
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right">
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Platform Development</b></span>
            </h3>
            <p>
            We Provide a Complete IoT platform with a software framework that enables businesses to manage, monitor, and control IoT devices and data. IoT platform development services provide customized solutions that meet specific business needs.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-right">
            <img src={logo8} alt="loading..." />
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row  paragraph1">
        <div className="col-md-5" data-aos="zoom-in-right">
            <img src={logo3} alt="loading..."/>
          </div>

          <div className="col-md-5" data-aos="zoom-in-right">
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Device Management</b></span>
            </h3>
            <p>
            We Provide device management services which include device provisioning, monitoring, and maintenance solutions. This service helps ensure that devices are secure, reliable, and always connected to the network.
            </p>
          </div>
          
        </div>
      </div>

      <div className="container">
        <div className="row paragraph1">

         
          <div className="col-md-5" data-aos="zoom-in-left">
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Analytics</b></span>
            </h3>
            <p>
            We Provide analytics services that help businesses derive insights from the data collected by IoT devices. This service includes data processing, data analysis, and data visualization.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" >
            <img src={logo4} alt="loading..."/>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">
        <div className="col-md-5" data-aos="zoom-in-right">
            <img src={logo5} alt="loading..."  />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right">
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Security</b></span>
            </h3>
            <p>
            We provide solutions to protect IoT devices, networks, and data from cyber threats. This includes secure device authentication, data encryption, and secure network connections.
            </p>
          </div>
          
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

        
          <div className="col-md-5" data-aos="zoom-in-left">
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Application Development</b></span>
            </h3>
            <p>
            We Provide IoT application development services which include Developing custom applications that run on IoT devices. These applications can automate processes, enable remote control, and provide real-time data visualization.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left">
            <img src={logo6} alt="loading..."  />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">
        <div className="col-md-5" data-aos="zoom-in-right">
            <img src={logo7} alt="loading..." />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right">
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Integration</b></span>
            </h3>
            <p>
            We Provide IoT integration services to help businesses integrate IoT solutions with existing systems and processes. This includes integrating IoT devices with enterprise software, cloud platforms, and other third-party systems.
            </p>
          </div>
         
        </div>
      </div>


      <div className="container">
        <div className="row  paragraph1">
          <div className="col-md-10 mt-5" data-aos="zoom-in-left">
            <p>
            Collectively, we provide businesses with the tools and expertise needed to harness the power of IoT and transform their operations. <NavLink to="/contact" >Get in touch with us for more Details.</NavLink>
            </p>
          </div>
        </div>
        <NavLink to="/cloudcomputing" className="" style={{float:"right",color:"rgb(255,102,0)"}} data-aos="zoom-in-left"><p>Explore Cloud Computing</p>
		</NavLink>
      </div>

    </div>
  );
};

export default IOT;
