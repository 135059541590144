import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import proandauto from '../Industries/Automotive&Ancillaries/proandauto.jpg'
import banner from '../Industries/Automotive & Ancillaries/banner.png'
import datadriven from '../Industries/Automotive&Ancillaries/datadriven.avif'
import qualitycontrol from '../Industries/Automotive&Ancillaries/Quality Control and Assurance.avif'
import supplychain from '../Industries/Automotive&Ancillaries/Supply Chain Visibility.jpg'
import remotemoniter from '../Industries/Automotive&Ancillaries/Remote Monitoring and Control.avif'
import energyef from '../Industries/Automotive&Ancillaries/Energy Efficiency Solutions.avif'
import preductive from '../Industries/Automotive&Ancillaries/Predictive Maintenance.svg'
import tracebility from '../Industries/Automotive&Ancillaries/traceability.webp'



const AutomotiveAndAncillaries = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);

	
	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

	return (
		<div className="container-fluid" style={{overflow:"hidden"}}>
			
			<header className="" style={{marginTop:"1rem"}}>
				<img src={banner} alt="Logo" className='aboutimg'/>
				
				<div className="container">
					<div className="row">
						<div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span> In the Automotive Manufacturing industry, our Automation, Digitization and Traceability solutions are designed to elevate operational efficiency and drive excellence throughout the production lifecycle. We offer a comprehensive suite of solutions tailored to meet the unique demands of this dynamic sector:
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<div className="container">
				<div className="row paragraph1 mt-5">
					
					<div className="col-md-5" data-aos="zoom-in-right" style={{margin:"auto"}}>
						<img src={proandauto} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{margin:"auto"}} >
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Production Line Automation</b></span>
					</h3>
						<p>
						Streamline your assembly processes with our intelligent automation systems that optimize production workflows, reduce cycle times, and enhance product quality. Our solutions encompass Robotic Automation, Smart Conveyor Systems, Assembly Poka-yoke Systems and Real-Time Monitoring to ensure seamless production operations.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
					
					<div className="col-md-5" data-aos="zoom-in-left" style={{margin:"auto"}}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Data-Driven Insights</b></span>
					</h3>
						<p>
						Harness the power of data analytics to gain actionable insights into your manufacturing processes. Our solutions leverage IoT Sensors, AI-Powered Analytics, and Machine Learning algorithms to identify inefficiencies, predict maintenance needs, and optimize resource allocation.


						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{margin:"auto"}}>
						<img src={datadriven} alt="loading..."  />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right" style={{margin:"auto"}}>
						<img src={qualitycontrol} alt="loading..."/>
					</div>
					<div className="col-md-5"  data-aos="zoom-in-right" style={{margin:"auto"}}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Quality Control and Assurance</b></span>
					</h3>
						<p>
						Ensure perfect product quality with our automated quality control systems. From visual inspections utilizing computer vision to non-destructive testing techniques, our solutions guarantee that each product leaving the assembly line meets the highest standards.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5"  data-aos="zoom-in-left" style={{margin:"auto"}}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Traceability and Serialization</b></span>
					</h3>
						<p>
						Implement end-to-end traceability solutions that enable you to track each component's journey from raw materials to the final product. This not only enhances accountability but also facilitates compliance with industry regulations and quality certifications.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{margin:"auto"}}>
						<img src={tracebility} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right" style={{margin:"auto"}}>
						<img src={supplychain} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{margin:"auto"}}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Supply Chain Visibility</b></span>
					</h3>
						<p>
						Enhance supply chain management by digitizing processes, providing real-time visibility into inventory levels, and automating reorder points. This ensures that the right parts are available at the when needed, minimizing downtime and disruptions.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-left" style={{margin:"auto"}}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Predictive Maintenance</b></span>
					</h3>
						<p>
						Prevent costly downtime by utilizing predictive maintenance solutions that monitor equipment health in real time. By analysing sensor data and historical performance, our systems predict potential failures and recommend maintenance actions before they occur.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{margin:"auto"}}>
						<img src={preductive} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right" style={{margin:"auto"}}>
						<img src={energyef} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{margin:"auto"}}>
						<br />
						<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Energy Efficiency Solutions</b></span>
					</h3>
						<p>
						Promote sustainability by implementing energy-efficient practices across your facilities. Our solutions optimize energy usage, reduce waste, and lower operational costs while contributing to a greener future.
						</p>
					</div>
				</div>
			</div>



			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-left" style={{margin:"auto"}}> 
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Remote Monitoring and Control</b></span>
					</h3>
						<p>
						Gain the ability to monitor and control manufacturing processes through connected platforms remotely. This empowers you to make informed decisions, troubleshoot issues, and optimize operations from anywhere.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{margin:"auto"}}>
						<img src={preductive} alt="loading..."/>
					</div>
				</div>
			</div>


					
			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-11 mt-5" data-aos="zoom-in-left" style={{margin:"auto"}}>
						<p>
						At Thetavega, we understand the complex needs of the Automotive Manufacturing Industry. Our Automation Digitization and Traceability solutions are your gateway to achieving enhanced efficiency, superior product quality, and unmatched competitiveness in the rapidly evolving automotive landscape. Partner with us to drive innovation and secure a prosperous future for your manufacturing endeavours.
						</p>
					</div>
				</div>
				
			</div>

		</div>
	);
};




export default AutomotiveAndAncillaries
