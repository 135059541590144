import React from "react";

import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../About.css'
import banner from '../Industries/AGRI/banner.png'
import agrieco from '../Industries/AGRI/agriecosytem.jpg'
import cropmonitor from '../Industries/AGRI/cropmoniter.webp'
import datadriven from '../Industries/AGRI/datadriven.jpg'
import remotemoniter from '../Industries/AGRI/remotemoniter.avif'
import smartirri from '../Industries/AGRI/smartirrigation.jpg'
import soilhealth from '../Industries/AGRI/soilhealth.jpg'
import supply from '../Industries/chages/supplychaintrace.webp'
import climatereli from '../Industries/chages/climaterelience.avif'





const Agriculture = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);


	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	}, []);

	return (
		<div className="container-fluid" style={{ overflow: "hidden" }}>
			
			<header className="" style={{ marginTop: "1rem" }}>
				<img src={banner} alt="Logo" className='aboutimg' />

				<div className="container">
					<div className="row">
						<div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>In the agriculture industry, our Automation, Digitization and Traceability solutions are engineered to revolutionize farming practices, optimize resource utilization, and ensure sustainable food production. We offer a comprehensive suite of solutions tailored to address the specific challenges of agriculture:
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={smartirri} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Smart Irrigation </b></span>
						</h3>
						<p>
							Utilize automated irrigation systems that adjust watering schedules based on real-time weather data, soil moisture levels, and crop needs. Conserve water while ensuring optimal plant growth.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Crop Monitoring and Management </b></span>
						</h3>
						<p>
							Deploy sensors and drones to monitor crop health, growth, and disease patterns. Analyse data to make informed decisions about pest control, fertilization, and other interventions.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={cropmonitor} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={supply} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Supply Chain Traceability</b></span>
						</h3>
						<p>
							Implement traceability solutions to track the origin and journey of agricultural products from farm to table. Ensure transparency, quality control, and compliance with food safety standards.

						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Climate Resilience</b></span>
						</h3>
						<p>
							Deploy weather forecasting and climate monitoring solutions to anticipate extreme weather events and adapt farming practices accordingly.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={climatereli} alt="loading..."  />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={datadriven} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Data-Driven Decision Making</b></span>
						</h3>
						<p>
							Centralize data from various sources, such as sensors, drones, and equipment, into a unified platform. Analyse data to make informed decisions about planting, resource allocation, and risk management.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>AgTech Ecosystem Integration</b></span>
						</h3>
						<p>
							Integrate various agricultural technologies into a unified ecosystem. This might include connecting farm management software, IoT devices, and other tools to streamline operations.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={agrieco} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={remotemoniter} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Remote Monitoring and Control</b></span>
						</h3>
						<p>
							Gain remote access to farming equipment and processes for real-time monitoring and control. This capability allows for prompt interventions and adjustments even when off-site.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Soil Health Management</b></span>
						</h3>
						<p>
							Implement solutions to monitor and enhance soil health through precision fertilization, cover cropping, and sustainable soil management practices.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={soilhealth} alt="loading..."/>
					</div>
				</div>
			</div>



			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-11 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<p>
							At Thetavega, we understand the critical role of agriculture in feeding the world. Our Automation, Digitization and Traceability solutions are designed to empower farmers and agribusinesses with the tools needed to improve efficiency, sustainability, and profitability. Join us in shaping the future of agriculture and contributing to global food security.
						</p>
					</div>
				</div>
				{/* <NavLink to="/industries/pharmaceuticals" className="" style={{float:"right",color:"rgb(255,102,0)",fontSize:"1.2rem", marginTop:"2rem",marginRight:"5rem" }} data-aos="zoom-in-left">Explore to Pharmaceuticals
		</NavLink> */}
			</div>

		</div>
	);
};

export default Agriculture
