import React from "react";
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../About.css'
import bannermain from '../Products/Stepwise Connect/banner.png'
import custo from '../Products/Stepwise Connect/Customizable Displays.jpg'
import datadriven from '../Products/Stepwise Connect/Data-Driven Decisions.png'
import digital from '../Products/Stepwise Connect/Digital SOPs.jpg'
import enhancedqua from '../Products/Stepwise Connect/Enhanced Quality.avif'
import enhancecomm from '../Products/Stepwise Connect/Enhanced Communication.jpg'
import expthe from '../Products/Stepwise Connect/Experience the Future Today.avif'
import improved from '../Products/Stepwise Connect/Improved Efficiency.avif'
import IOt from '../Products/Stepwise Connect/IoT Integration.jpg'
import live from '../Products/Stepwise Connect/Live Productivity Tracking.jpg'
import optimized from '../Products/Stepwise Connect/Optimized Production.png'
import proactive from '../Products/Stepwise Connect/Proactive Issue Resolution.jpg'
import qualitydata from '../Products/Stepwise Connect/Quality Data Insights.avif'
import qualitypoints from '../Products/Stepwise Connect/Quality Points & Alerts.avif'
import remotemgmt from '../Products/Stepwise Connect/Remote Management.avif'
import { NavLink } from 'react-router-dom';







const StepwiseConnect = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);


	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	}, []);

	return (
		<div className="container-fluid" style={{ overflow: "hidden" }}>
			{/* <div className="bg0">
				<p className="col-md-12" >
					<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Autonomous Machine </NavLink>
				</p>
			</div> */}

			<header className="" style={{ marginTop: "1rem" }}>
				<img src={bannermain} alt="Logo" className='aboutimg' />

				<div className="container">
					<div className="row">
						<div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>Revolutionize your shop floor operations with our cutting-edge Smart Manufacturing Screen – the ultimate solution to streamline your processes, enhance productivity, and elevate quality control. Say goodbye to the hassle of paper-based SOPs, quality points, alerts, and messages. Embrace the digital transformation and empower your workforce with real-time data insights and seamless integration with production systems and IoT devices.
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Key Features</b></h1>
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={digital} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Digital SOPs</b></span>
						</h3>
						<p>
						Replace traditional paper-based Standard Operating Procedures (SOPs) with dynamic digital displays. Ensure that your team has access to the latest instructions, reducing errors and improving compliance.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Quality Points & Alerts</b></span>
						</h3>
						<p>
							Instantly communicate critical quality checkpoints and alerts to your operators. Minimize defects, rework, and downtime by providing clear, real-time guidance on the shop floor.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={qualitypoints} alt="loading..." style={{ height: "20rem", width: '90%' }}	 />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={live} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Live Productivity Tracking</b></span>
						</h3>
						<p>
							Keep your finger on the pulse of your operations with real-time productivity data. Monitor key performance indicators (KPIs) and identify opportunities for optimization.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b> Quality Data Insights</b></span>
						</h3>
						<p>
							Elevate your quality control process by integrating real-time quality data. Detect trends, identify issues early, and implement corrective actions promptly.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={qualitydata} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={IOt} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>IoT Integration</b></span>
						</h3>
						<p>
							Connect seamlessly with IoT devices and sensors to display live data feeds. Showcase critical information such as machine status, temperature, and other relevant metrics.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Customizable Displays</b></span>
						</h3>
						<p>
							Tailor the screen layout to your specific needs. Display relevant information where and when it's needed most, ensuring optimal visibility and usability.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={custo} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={remotemgmt} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Remote Management</b></span>
						</h3>
						<p>
							Update content and configurations remotely, ensuring that your shop floor always has access to the latest information and instructions.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Enhanced Communication</b></span>
						</h3>
						<p>
							Use the screen to share important announcements, safety messages, and updates, fostering a more informed and engaged workforce.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={enhancecomm} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
				</div>
			</div>
			<h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Benefits</b></h1>
			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={improved} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Improved Efficiency</b></span>
						</h3>
						<p>
							Eliminate the time-consuming process of printing, distributing, and updating paper documents. Real-time updates ensure your team always has the latest information.
						</p>
					</div>
				</div>
			</div>


			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Enhanced Quality</b></span>
						</h3>
						<p>
							Reduce human errors and inconsistencies by displaying step-by-step instructions and quality checkpoints directly on the screen.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={enhancedqua} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={optimized} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Optimized Production</b></span>
						</h3>
						<p>
							Monitor real-time productivity metrics to identify bottlenecks and opportunities for process improvement.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Proactive Issue Resolution: </b></span>
						</h3>
						<p>
							Instant alerts and data insights empower your team to address quality issues promptly, minimizing costly disruptions.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={proactive} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
				</div>
			</div>


			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={datadriven} alt="loading..." style={{ height: "16rem", width: '90%' }}/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Data-Driven Decisions</b></span>
						</h3>
						<p>
							Access actionable insights from integrated quality and productivity data to make informed decisions and drive continuous improvement.
						</p>
					</div>
				</div>
			</div>





			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-10 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<p>
							Elevate your manufacturing shop floor with the Smart Manufacturing Screen. Embrace innovation, digitize your processes, and empower your team to achieve new levels of efficiency, quality, and collaboration.<br/><br/>
							<b>Join the digital revolution. Transform your shop floor with the power of smart technology.</b><br/><br/>
							For inquiries and a personalized demonstration, contact us at <NavLink to="mailto:sales@thetavega.tech?subject=Sales" >sales@thetavega.tech</NavLink>

						</p>
					</div>
				</div>
				{/* <NavLink to="/industries/pharmaceuticals" className="" style={{float:"right",color:"rgb(255,102,0)",fontSize:"1.2rem", marginTop:"2rem",marginRight:"5rem" }} data-aos="zoom-in-left">Explore to Pharmaceuticals
		</NavLink> */}
			</div>

		</div>
	);
};


export default StepwiseConnect
