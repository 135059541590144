import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import banner from '../Industries/STEEL AND MINING/banner.png'
import datanalytics from '../Industries/STEEL AND MINING/Data Analytics.jpg'
import energyeffi from '../Industries/STEEL AND MINING/Energy Efficiency Solutions.avif'
import enviornm from '../Industries/STEEL AND MINING/Environmental Monitoring.avif'
import miningauto from '../Industries/STEEL AND MINING/Mining Automation.avif'
import predictive from '../Industries/STEEL AND MINING/Predictive Maintenance.jpg'
import processopt from '../Industries/STEEL AND MINING/Process Optimization.avif'
import remotemoni from '../Industries/STEEL AND MINING/Remote Monitoring and Control.avif'
import smartasset from '../Industries/STEEL AND MINING/Smart Asset Management.avif'
//import tracesup from '../Industries/STEEL AND MINING/Remote Monitoring and Control.avif'





const SteelAndMining = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);


	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	}, []);

	return (
		<div className="container-fluid" style={{ overflow: "hidden" }}>
			{/* <div className="bg0">
				<p className="col-md-12" >
					<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Autonomous Machine </NavLink>
				</p>
			</div> */}

			<header className="" style={{ marginTop: "" }}>
				<img src={banner} alt="Logo" className='aboutimg' />

				<div className="container">
					<div className="row">
						<div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>In the In the Iron, Steel and Mining industries, where operational efficiency and safety are of utmost importance, our Automation, Digitization and Traceability solutions are tailored to optimize processes and elevate productivity. We offer a comprehensive range of solutions designed to address the unique challenges of these sectors:
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={miningauto} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Mining Automation </b></span>
						</h3>
						<p>
							Revolutionize mining operations with advanced automation technologies. Our solutions encompass Autonomous Transport Systems, Remote-Controlled Drilling Rigs, and Sensor-Equipped machinery that enhance safety and efficiency in challenging environments.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Smart Asset Management</b></span>
						</h3>
						<p>
							Digitize asset tracking and management through IoT-enabled sensors. Monitor the condition and location of mining equipment and machinery in real-time, optimizing maintenance schedules and extending the lifespan of critical assets.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={smartasset} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={processopt} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Process Optimization</b></span>
						</h3>
						<p>
							Employ data-driven insights to optimize ore processing and steel production. Our solutions leverage machine learning and AI algorithms to enhance operational efficiency, reduce energy consumption, and minimize waste.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Traceability and Supply Chain Integration</b></span>
						</h3>
						<p>
							Ensure transparency and traceability across the supply chain, from mining sites to steel production facilities. Implement traceability solutions to track the origin and quality of raw materials, enabling compliance with industry standards and regulations.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={energyeffi} alt="loading..."  />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={predictive} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Predictive Maintenance</b></span>
						</h3>
						<p>
							Prevent costly downtime by predicting equipment failures and maintenance needs. Our predictive maintenance solutions analyse sensor data and historical performance to optimize maintenance schedules and minimize disruptions
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Energy Management</b></span>
						</h3>
						<p>
							Optimize energy usage through smart energy management systems. Monitor energy consumption in real-time, identify inefficiencies, and implement strategies to reduce energy costs and environmental impact.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={energyeffi} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={remotemoni} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Remote Monitoring and Control</b></span>
						</h3>
						<p>
							Gain remote access to critical systems and processes for real-time monitoring and control. This capability allows you to make informed decisions, address issues promptly, and optimize operations from remote locations.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Environmental Monitoring</b></span>
						</h3>
						<p>
							Ensure compliance with environmental regulations by implementing solutions for monitoring air and water quality, emissions, and waste management. Contribute to sustainable practices while minimizing your environmental footprint.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={enviornm} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={datanalytics} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Data Analytics and Reporting</b></span>
						</h3>
						<p>
							Utilize advanced data analytics to generate insightful reports on production performance, efficiency, and compliance. Make informed decisions based on accurate data and drive continuous improvement initiatives.
						</p>
					</div>
				</div>
			</div>




			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-11 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<p>
							At Thetavega, we recognize the complex challenges faced by the steel and mining industries. Our Automation Digitization and Traceability solutions are engineered to empower you with the tools needed to enhance productivity, safety, and sustainability. Partner with us to unlock new levels of operational excellence and secure a prosperous future for your steel and mining endeavours.
						</p>
					</div>
				</div>
				{/* <NavLink to="/industries/pharmaceuticals" className="" style={{float:"right",color:"rgb(255,102,0)",fontSize:"1.2rem", marginTop:"2rem",marginRight:"5rem" }} data-aos="zoom-in-left">Explore to Pharmaceuticals
		</NavLink> */}
			</div>

		</div>
	);
};



export default SteelAndMining