import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import customer from "../img2/customer.jpg" 
import occupancey from '../img2/shedule.avif'




const SupportAndDemo = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);


    useEffect(() => {
        AOS.init(); // Initialize the AOS library
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: "hidden" }}>


            <header className="" style={{ marginTop: "1rem" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
                            <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
                            ><b>Support & Demo</b></h1>
                        
                        </div>
                    </div>
                </div>
            </header>
            {/* --------------------------------------SECTION----------------------------------------------------- */}

            <div className="container dataparts">
                <div className="row paragraph1 ">

                    <div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
                        <img src={customer} alt="add map " />
                    </div>
                    <div className="col-md-6" data-aos="zoom-in-right" style={{ margin: "auto" }} >
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Customer-Centric Support </b></span>
                        </h3>
                        <p>
                        ADISRA is committed to providing outstanding customer service with a priority on client happiness. We distinguish ourselves by continuously providing a satisfying and enlightening support experience and by providing a variety of options to accommodate the particular preferences of each client.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row  paragraph1">

                    <div className="col-md-6" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Schedule A Demo </b></span>
                        </h3>
                        <p>
                            Certainly, we are here to assist you. If you'd like a personalized demonstration or one-on-one assistance, Schedule a webinar here. We are dedicated to providing you with the support and guidance you need to make the most of our products and services.
                            Feel free to reach out, and we'll be happy to help
                        </p>
                        <div className="row">
                            <a href="#" className={"btn btn-warning "} target="_blank" > Shedule a demo</a>
                        </div>
                    </div>
                    <div className="col-md-4 mt-4" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <img src={occupancey} alt="loading..." />
                    </div>
                </div>
            </div>





        </div>
    );
};


export default SupportAndDemo
