import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import banner from '../Industries/FOOD PHARMA/banner.png'
import batchprocess from '../Industries/FOOD PHARMA/Batch and Process Automation.jpg'
import brewingprocess from '../Industries/FOOD PHARMA/Brewing Process Automation.avif'
import compliancemgmt from '../Industries/FOOD PHARMA/Compliance Management.jpg'
import dataintegrity from '../Industries/FOOD PHARMA/Data Integrity and Documentation.png'
import inventory from '../Industries/FOOD PHARMA/Inventory Management.avif'
import production from '../Industries/FOOD PHARMA/Production Line Automation.jpg'
import qualitycomp from '../Industries/FOOD PHARMA/QUALITY CONTROL AND COMPLIANCE.png'
import qualityinsc from '../Industries/FOOD PHARMA/Quality Control and Inspection.avif'
import qualityBrews from '../Industries/FOOD PHARMA/Quality Control for Brews.avif'
import remotemoniter from '../Industries/FOOD PHARMA/Remote Monitoring and Control.avif'
import serilia from '../Industries/FOOD PHARMA/Serialization track trace.png'
import supplychain from '../Industries/FOOD PHARMA/Supply Chain Visibility.webp'
import traceRecall from '../Industries/FOOD PHARMA/Traceability and Recall Readiness.svg'
import traceingredient from '../Industries/FOOD PHARMA/Traceability of Ingredients.jpg'



const Pharmaceuticals = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);


	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	}, []);

	return (
		<div className="container-fluid" style={{ overflow: "hidden" }}>
			{/* <div className="bg0">
				<p className="col-md-12" >
					<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Autonomous Machine </NavLink>
				</p>
			</div> */}

			<header className="" style={{ marginTop: "0rem" }}>
				<img src={banner} alt="Logo" className='aboutimg' />

				<div className="container">
					<div className="row">
						<div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>In the Pharma, Food, and Breweries industries, our Automation, Digitization and Traceability solutions are designed to uphold product safety, quality, and compliance while optimizing operational efficiency. We offer a comprehensive suite of solutions tailored to address the specific needs of these sectors:
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Pharmaceutical Industry</b></h1>
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={batchprocess} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Batch and Process Automation</b></span>
						</h3>
						<p>
							Streamline pharmaceutical manufacturing through automated batch processing and real-time monitoring. Ensure consistency, reduce errors, and enhance product quality, adhering to regulatory requirements.
						</p>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Serialization and Track & Trace</b></span>
						</h3>
						<p>
							Implement serialization solutions to track each unit of medication from production to distribution. Prevent counterfeiting, ensure regulatory compliance, and enable rapid recalls.

						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={serilia} alt="loading..."/>
					</div>
				</div>
			</div>


			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={qualitycomp} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Quality Control and Compliance</b></span>
						</h3>
						<p>
							Utilize automated inspection systems to maintain pharmaceutical product quality and regulatory compliance. Vision systems, spectroscopy, and other technologies detect deviations and ensure consistent quality.

						</p>
					</div>

				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Data Integrity and Documentation</b></span>
						</h3>
						<p>
							Digitize data management and documentation processes to ensure data integrity and regulatory compliance. Provide secure electronic records and electronic signatures (ERES) in line with industry standards like FDA 21 CFR Part 11.

						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={dataintegrity} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={supplychain} alt="loading..."  />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Supply Chain Visibility</b></span>
						</h3>
						<p>
							Enhance supply chain management by employing real-time visibility into inventory levels, demand forecasting, and distribution. Optimize logistics, reduce waste, and ensure timely deliveries.
						</p>
					</div>
					
				</div>
			</div>



			<h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Food Industry</b></h1>
			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Production Line Automation</b></span>
						</h3>
						<p>
							Implement automated production lines to enhance efficiency and consistency in food processing and packaging. From mixing ingredients to packaging finished goods, automation reduces human error and ensures uniformity.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={production} alt="loading..."  />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={qualityinsc} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Quality Control and Inspection</b></span>
						</h3>
						<p>
							Utilize computer vision and sensory technologies for automated quality control and inspection. Detect contaminants, defects, and irregularities in food products, maintaining high-quality standards.

						</p>
					</div>
					
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Traceability and Recall Readiness</b></span>
						</h3>
						<p>
							Implement end-to-end traceability solutions to track ingredients and products throughout the supply chain. Be prepared for swift recalls if necessary, minimizing potential risks to consumer health.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={traceRecall} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={compliancemgmt} alt="loading..." />
					</div>

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Compliance Management</b></span>
						</h3>
						<p>
							Digitize compliance processes to ensure adherence to food safety regulations. Maintain accurate records, perform audits efficiently, and demonstrate compliance during inspections.
						</p>
					</div>
					
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Inventory Management</b></span>
						</h3>
						<p>
							Digitize inventory tracking and management to prevent overstocking, reduce waste, and ensure optimal product rotation.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={inventory} alt="loading..."/>
					</div>
				</div>
			</div>

			<h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Breweries Industry</b></h1>

			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={brewingprocess} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b> Brewing Process Automation</b></span>
						</h3>
						<p>
							Automate brewing processes, from mashing and fermentation to packaging. Maintain consistent quality, reduce production time, and optimize resource utilization.
						</p>
					</div>
					
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Quality Control for Brews</b></span>
						</h3>
						<p>
							Implement automated quality control systems to ensure consistency in flavour, aroma, and other characteristics of brewed products.
						</p>
					</div>
					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={qualityBrews} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={traceingredient} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Traceability of Ingredients</b></span>
						</h3>
						<p>
							Implement traceability solutions to track the origin and journey of ingredients used in brewing. Enhance transparency and comply with regulations.
						</p>
					</div>
					
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Real-time Monitoring and Reporting</b></span>
						</h3>
						<p>
							Utilize sensors and data analytics to monitor brewing processes and generate real-time reports. Optimize operations and troubleshoot issues promptly.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={remotemoniter} alt="loading..."/>
					</div>
				</div>
			</div>





			<div className="container">
				<div className="row  paragraph1" >
					<div className="col-md-11 mt-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<p>
							At Thetavega, we understand the unique requirements of the Food, Pharma, and Breweries industries. Our Automation, Digitization and Traceability solutions are designed to elevate safety, quality, and efficiency across these sectors. Partner with us to drive innovation and ensure the highest standards in production and compliance.
						</p>
					</div>
				</div>
				{/* <NavLink to="/industries/pharmaceuticals" className="" style={{float:"right",color:"rgb(255,102,0)",fontSize:"1.2rem", marginTop:"2rem",marginRight:"5rem" }} data-aos="zoom-in-left">Explore to Pharmaceuticals
		</NavLink> */}
			</div>

		</div>
	);
};







export default Pharmaceuticals
