import React from "react";
import '../About.css'
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bannermain from '../Products/NexaBridge IoT/banner.png'
import cuttingedge from '../Products/NexaBridge IoT/cutting edge.webp'
import effortless from '../Products/NexaBridge IoT/Effortless Integration.jpg'
import e2e from '../Products/NexaBridge IoT/End-to-End Security.avif'
import flexibility from '../Products/NexaBridge IoT/Flexibility and Compatibility.avif'
import future from '../Products/NexaBridge IoT/Future-Ready Solutions.webp'
import gateway from '../Products/NexaBridge IoT/Gateway Excellence.jpg'
import operational from '../Products/NexaBridge IoT/operational-efficiency.png'
import realtime from '../Products/NexaBridge IoT/Real-time Insights.avif'
import scalability from '../Products/NexaBridge IoT/Scalability Redefined.png'
import securedata from '../Products/NexaBridge IoT/Secure Data Transfer.avif'
import universal from '../Products/NexaBridge IoT/universalconnection.avif'
import { Link } from "react-router-dom";




const NexaBridgeIOT = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);


  useEffect(() => {
    AOS.init(); // Initialize the AOS library
  }, []);

  return (
    <div className="container-fluid" style={{overflow:"hidden"}}>
    

      <header className="" style={{ marginTop: "1rem" }}>
        <img src={bannermain} alt="Logo" className='aboutimg' />
        
        <div className="container">
          <div className="row">
            <div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
              <p>
                <span style={{ color: "rgb(255,102,0)" }}></span>At Thetavega, we are at the forefront of the IoT revolution, bringing you a diverse range of IoT gateways that bridge the gap between your field devices and the cloud/server, enabling effortless and secure data communication. With a legacy of innovation and a commitment to excellence, we present IoT gateways designed to transform your data into actionable insights, opening doors to unprecedented efficiency and intelligence.
              </p>
            </div>
          </div>
        </div>
      </header>
      {/* --------------------------------------SECTION----------------------------------------------------- */}
      <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Our IoT Gateway Solutions:</b></h1>
      <div className="container dataparts">
        <div className="row paragraph1 mt-5">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={gateway} alt="loading..." />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right"  style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Gateway Excellence </b></span>
            </h3>
            <p>
              Our IoT gateways are meticulously engineered to provide a robust and reliable connection between your field devices and the digital realm. Count on them to handle data transmission seamlessly, regardless of scale or complexity.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Universal Connectivity </b></span>
            </h3>
            <p>
              Embrace versatility with gateways that support a multitude of communication protocols. From MQTT and Modbus to LoRaWAN and Zigbee, our gateways ensure your devices can communicate effortlessly with your chosen cloud or server platform.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={universal} alt="loading..."/>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={securedata} alt="loading..."/>
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Secure Data Transfer</b></span>
            </h3>
            <p>
              Security is paramount. Our gateways employ state-of-the-art encryption and authentication mechanisms to safeguard your data as it travels from the field to the cloud, ensuring your information remains confidential and protected.

            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Edge Computing Capabilities</b></span>
            </h3>
            <p>
              Pushing the boundaries of intelligence, our gateways are equipped with edge computing capabilities. Process data locally to reduce latency, enhance response times, and minimize data traffic.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={cuttingedge} alt="loading..."/>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={scalability} alt="loading..." />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Scalability Redefined</b></span>
            </h3>
            <p>
              Whether you're starting small or aiming big, our gateways are designed to scale with your needs. Seamlessly integrate additional devices and expand your network without compromising on performance.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Effortless Integration</b></span>
            </h3>
            <p>
              The integration process is made simple, with user-friendly interfaces and comprehensive documentation. Get your IoT ecosystem up and running quickly and efficiently.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={effortless} alt="loading..."/>
          </div>
        </div>
      </div>

      <h2 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Benefits</b></h2>
      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={realtime} alt="loading..." />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <br />
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Real-time Insights</b></span>
            </h3>
            <p>
              Capture, process, and transmit data in real-time, enabling informed decision-making and immediate actions based on actual conditions.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Operational Efficiency</b></span>
            </h3>
            <p>
              Optimize processes, reduce downtime, and enhance resource allocation with the power of data-driven insights.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={operational} alt="loading..." />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={e2e} alt="loading..." />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <br />
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>End-to-End Security</b></span>
            </h3>
            <p>
              Ensure the integrity of your data with robust encryption, authentication, and secure communication protocols.
            </p>
          </div>
        </div>
      </div>


      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Flexibility and Compatibility</b></span>
            </h3>
            <p>
              Seamlessly connect diverse field devices and sensors, irrespective of their communication protocols or manufacturers.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={flexibility} alt="loading..." />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={future} alt="loading..."  />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <br />
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Future-Ready Solutions</b></span>
            </h3>
            <p>
              Stay ahead of the curve with gateways that are built to adapt and integrate with emerging technologies and standards.
            </p>
          </div>
        </div>
      </div>





      <div className="container">
        <div className="row  paragraph1">
          <div className="col-md-11 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <p>
              Experience the Future of Connectivity:
              At Thetavega, we are committed to transforming the way industries harness the potential of the IoT. Our IoT gateways empower businesses to streamline operations, drive innovation, and extract actionable insights from their data. Join us in shaping a smarter, more connected world.
              Connecting the present to the future - Explore our IoT gateways today.
              For inquiries, technical specifications, and custom solutions, contact us at <Link to="mailto:sales@thetavega.tech?subject=Sales" >sales@thetavega.tech</Link>
            </p>
          </div>
        </div>
       
      </div>

    </div>
  );
};



export default NexaBridgeIOT
