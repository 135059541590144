import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Slide4 from "./Slide4";
import Niche from "./Pages/Niche";
import Element from "./Pages/Element";
import logo from "./imgs/banner.png";
import logo1 from "./imgs/banner2.png";
import logo2 from "./imgs/ADISRA - Thetavega.png";
import logo3 from "./imgs/Building Management System.png";
import logo4 from "./imgs/plc1.png";
import "./Slide.css";

const Slides = () => {
  return (
    <div>
      <div className="slides">
        <Carousel
          showArrows={true}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={3000}
          transitionTime={1000}
          emulateTouch={true}
          stopOnHover={false}
          showIndicators={true}
          selectedItem={0}
          renderThumbs={() => null}
          transitionEffect="fade"
        >
          <div>
            <img src={logo} alt="Slide 1" className="slide-image" />
          </div>
          <div>
            <img src={logo1} alt="Slide 2" className="slide-image" />
          </div>
          <div>
            <img src={logo2} alt="Slide 3" className="slide-image" />
          </div>
          <div>
            <img src={logo3} alt="Slide 4" className="slide-image" />
          </div>
          <div>
            <img src={logo4} alt="Slide 5" className="slide-image" />
          </div>
        </Carousel>
      </div>
      <hr />

      <div className="col">
        <div className="row">
          <div className="col">
            <Slide4 />
          </div>

          <div className="img">
            <Niche />
          </div>
          <div>
            <Element />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slides;
