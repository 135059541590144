import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import energyef from '../img2/knowledge.jpeg'
import indoor from '../Industries/BMS EMS & SMART CITY/Indoor Air Quality Monitoring.avif'
import occupancey from '../img2/insights.jpeg'
import smartbuilding from '../img2/smart.jpeg'






const Standards = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);


    useEffect(() => {
        AOS.init(); // Initialize the AOS library
    }, []);

    return (
        <div className="container-fluid " style={{ overflow: "hidden" }}>
            <div className="row">
                <header className="col-md-11" style={{ marginTop: "6rem" }} >
                <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "1rem" }} data-aos="fade-down"
              >Standards</h1>
                    <p>
                        <span style={{ color: "rgb(255,102,0" }}>ADISRA-THETAVEGA</span> products are built on globally recognized standards including HTML5, .NET, OPC, SQL, MQTT, XML, and SNMP. This ensures effortless integration with third-party software, seamless access to external data sources, and the capability to store data within a standard SQL database. Your data remains unbound to ADISRA-THETAVEGA SmartView, enabling accessibility from other third-party solutions. Our drivers adhere to industrial automation standard protocols, ensuring robust compatibility and reliability.challenges.
                    </p>
                </header>
            </div>



            {/* --------------------------------------SECTION----------------------------------------------------- */}
            {/* <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Building Management</b></h1> */}

            <div className="container dataparts">
                <div className="row paragraph1 mt-5">

                    <div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
                        <img src={smartbuilding} alt="loading..." style={{ height: "20rem", width: '100%' }} />
                    </div>
                    <div className="col-md-6" data-aos="zoom-in-right" style={{ margin: "auto" }} >
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>SMART VIEW</b></span>
                        </h3>
                        <p>
                            <ol>
                                <li>Simple, comprehensive, unified HMI, SCADA, and IIoT development software. </li>
                                <li>Dynamic interface that adapts to user actions within the development environment.</li>
                                <li>Seamless connectivity with devices through popular industrial protocols (MQTT, OPC UA, OPC DA) and standards-based protocols. </li>
                                <li>Compatibility with databases for efficient data management. </li>
                                <li>Accessible HMI/SCADA applications via any HTML5 compliant web browser. </li>
                                <li>Information delivery across desktops, tablets, and smartphones, ensuring accessibility regardless of the device. </li>
                                <li>Supports Compliance with FDA CFR Part 11 regulations. </li>
                                <li>Scalable from mobile devices to highly-redundant servers, accommodating customer growth. </li>
                            </ol>
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row  paragraph1">

                    <div className="col-md-6" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Insight View</b></span>
                        </h3>
                        <p className="">
                            <ol>
                                <li> Secure and managed Software-as-a-Service (SaaS) environment. </li>
                                <li> Advanced analytics for real-time data comparison (historical, plant, line, machine, user-defined). </li>
                                <li> Big data drill-down capabilities for in-depth analysis. </li>
                                <li>Role-based dashboards for customized views. </li>
                                <li> Preconfigured templates for easy deployment. </li>
                                <li> Robust data mining capabilities tracking metrics such as throughput, OEE, utilization, yield, availability, uptime, cycle time, and six major losses, among others.</li>
                            </ol>
                        </p>
                    </div>
                    <div className="col-md-4" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <img src={occupancey} alt="loading..." style={{ height: "18rem", width: '90%' }} />
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row paragraph1">

                    <div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
                        <img src={energyef} alt="loading..." style={{ height: "18rem", width: '100%' }} />
                    </div>
                    <div className="col-md-6" data-aos="zoom-in-right" style={{ margin: "auto" }}>
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>KnowledgeView</b></span>
                        </h3>
                        <p>
                            <ol>
                          <li> SaaS platform tailored for big data-driven maintenance strategies. </li>
                           <li> Cost reduction through the transition from reactive to predictive maintenance using machine learning and advanced analytics. </li>
                           <li>User-friendly, customizable dashboards for enhanced machine health insights, reduced downtime, and increased productivity. </li>
                          <li>Utilization of advanced machine learning algorithms including root-cause analysis, anomaly detection, naive Bayes, random forest, gradient boosting, support vector, and more. </li>
                          <li>al-time monitoring of operational parameters with an early warning system for proactive maintenance.</li>
                            </ol>
                        </p>
                    </div>
                </div>
            </div>

            {/* <div className="container">
                <div className="row  paragraph1">

                    <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>KnowledgeView</b></span>
                        </h3>
                        <p>
                            ADISRA-THETAVEGA <br />
                            KnowledgeView <br />
                            Artificial Intelligence Based Machine Learning Analysis & Interprets Data for Predictive Maintenance and Reporting


                        </p>
                    </div>
                    <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <img src={indoor} alt="loading..." style={{ height: "20rem", width: '90%' }} />
                    </div>
                </div>
            </div>



            <div className="container">
                <div className="row  paragraph1">
                    <div className="col-md-10 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <p>
                            "ADISRA-THETAVEGA offers versatile products that can be used individually or integrated seamlessly. Our Low-Code Platform and SmartView come with perpetual licenses, while InsightView and KnowledgeView are available as Software as a Service (SaaS). Our open platform allows compatibility with other solutions, providing flexible choices to meet your specific needs." <br /> <br />
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row  paragraph1">

                    <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Product Award</b></span>
                        </h3>
                        <p>
                            ADISRA SmartView was honoured with the prestigious Tech Brief's 2020 Product of the Year Award. Tech Brief, renowned as the leading magazine for OEM, Engineers, and Managers in the field of industrial automation, selects the award recipient through votes cast by its dedicated readership. This accolade is a testament to our commitment to excellence and innovation. We extend our heartfelt gratitude to the readers for their recognition and unwavering support.
                        </p>
                    </div>
                    <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <img src={indoor} alt="loading..." style={{ height: "20rem", width: '90%' }} />
                    </div>
                </div>
            </div> */}

            {/* <div>
          <p>
          Copyright Notice:  ADISRA®, ADISRA'S logo, InsightView®, and KnowledgeView® are registered trademarks of ADISRA, LLC.  © 2023   ADISRA, LLC.   
All Rights Reserved.

          </p>
        </div> */}

        </div>
    );
};


export default Standards