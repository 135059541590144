import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import energyef from '../img2/screenshote.png'
import indoor from '../img2/unnamed.png'
import occupancey from '../img2/boiler control.png'
import lowcode from '../img2/LowCode_Management.png'
import certificate from "../img2/award.png";






const Productstr = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);


  useEffect(() => {
    AOS.init(); // Initialize the AOS library
  }, []);

  return (
    <div className="container-fluid " style={{ overflow: "hidden" }}>
      <div className="row">
        <header className="col-md-11" style={{ marginTop: "6rem" }} >
        <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "1rem" }} data-aos="fade-down"
              >Product Strategy</h1>
          <p style={{textAlign:"justify"}}>
            <span style={{ color: "rgb(255,102,0" }}> Welcome to ADISRA® - THETAVEGA </span>your partner in superior production. We specialize in innovative solutions that increase operational effectiveness and help you reach your production targets.<br/><br/> A dynamic <b>"Low-Code"</b> platform for rapid application management is the cornerstone of our business strategy. Additionally, our <b>"SmartView"</b> provides a user-friendly HMI and SCADA solution, and <b>InsightView<sup>TM</sup></b> gives sophisticated analytics and OEE insights. <br/> <br/> The predictive analytics engine for data-driven decisions is <b>KnowledgeView<sup>TM</sup></b>. Together, these tools transform how companies respond to change and prosper.
          </p>
        </header>
      </div>



      {/* --------------------------------------SECTION----------------------------------------------------- */}
      {/* <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Building Management</b></h1> */}

      <div className="container dataparts">
        <div className="row paragraph1 mt-5">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={lowcode} alt="loading..." style={{ height: "18rem", width: '90%' }} />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Low-Code</b></span>
            </h3>
            <p className="text-center">
              ADISRA-THETAVEGA <br />
              Platform
              the agility to create, configure, delete, and adapt applications in run-time

            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>SmartView</b></span>
            </h3>
            <p className="text-center">
              ADISRA-THETAVEGA <br />

              (HMI/SCADA) Collect and Visualize Manufacturing Data <br />

            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={occupancey} alt="loading..." style={{ height: "18rem", width: '90%' }} />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row paragraph1">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={energyef} alt="loading..." style={{ height: "18rem", width: '90%' }} />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>InsightView</b></span>
            </h3>
            <p className="text-center">
              ADISRA-THETAVEGA <br />
              Interprets Data for Measuring Productivity of Machines and Processes


            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>KnowledgeView</b></span>
            </h3>
            <p className="text-center">
              ADISRA-THETAVEGA <br />
              Artificial Intelligence Based Machine Learning Analysis & Interprets Data for Predictive Maintenance and Reporting


            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={indoor} alt="loading..." style={{ height: "18rem", width: '90%' }} />
          </div>
        </div>
      </div>



      <div className="container">
        <div className="row  paragraph1">
          <div className="col-md-11 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <p>
              "ADISRA-THETAVEGA offers versatile products that can be used individually or integrated seamlessly. Our Low-Code Platform and SmartView come with perpetual licenses, while InsightView and KnowledgeView are available as Software as a Service (SaaS). Our open platform allows compatibility with other solutions, providing flexible choices to meet your specific needs." <br /> <br />
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">
        <div className="col-md-5" data-aos="zoom-in-right" style={{paddingLeft:"10rem"}}>
            <img src={certificate} alt="loading..." style={{ height: "18rem", width: '15rem'}} />
          </div>
          <div className="col-md-6" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Product Award</b></span>
            </h3>
            <p>
              ADISRA SmartView was honoured with the prestigious Tech Brief's 2020 Product of the Year Award. Tech Brief, renowned as the leading magazine for OEM, Engineers, and Managers in the field of industrial automation, selects the award recipient through votes cast by its dedicated readership. This accolade is a testament to our commitment to excellence and innovation. We extend our heartfelt gratitude to the readers for their recognition and unwavering support.
            </p>
          </div>
          
        </div>
      </div>

      <div style={{marginTop:"5rem"}}>
        <div className="col-md-11">
          <p style={{color:"gray", textAlign:"center", fontSize:"0.8rem"}}>
            Copyright Notice:  ADISRA®, ADISRA'S logo, InsightView®, and KnowledgeView® are registered trademarks of ADISRA, LLC.  © 2023   ADISRA, LLC.
            All Rights Reserved.

          </p>
        </div>
      </div>

    </div>
  );
};




export default Productstr;