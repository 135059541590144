import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import partner from "../img2/partner.avif"
import occupancey from '../img2/join.jpg'




const SystemIntegrator = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);


    useEffect(() => {
        AOS.init(); // Initialize the AOS library
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: "hidden" }}>


            <header className="" style={{ marginTop: "1rem" }}>
                {/* <img src="" alt="Add banner" className='aboutimg' /> */}

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
                            <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
                            ><b>System Integrator</b></h1>
                            {/* <p>
								<span style={{ color: "rgb(255,102,0)" }}></span> ADISRA's "Low-Code" and "SmartView" Platform are globally recognized for their role in developing SCADA/HMI applications across a wide spectrum of industries. Our solutions have made a significant impact in automotive, oil & gas, machinery, food and beverage, pharmaceutical, building automation, packaging, and more. The innovative technology and flexible scalability we offer cater to customers of all sizes. We provide the full range of functionality found in the most popular HMI/SCADA packages, all at a competitive and reasonable price point that aligns with the economic requirements of small to mid-market customers.
							</p> */}
                        </div>
                    </div>
                </div>
            </header>
            {/* --------------------------------------SECTION----------------------------------------------------- */}

            <div className="container dataparts">
                <div className="row paragraph1 mt-5">

                    <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
                        <img src={partner} alt="add map " style={{ height: "20rem", width: '90%' }} />
                    </div>
                    <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>System Integrator Partnership</b></span>
                        </h3>
                        <p>
                            System Integrators are esteemed partners within the ADISRA-THETAVEGA worldwide ecosystem. Our commitment is to ensure that our valued system integrators not only grow alongside us but also have the resources they need to thrive.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row  paragraph1">

                    <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Join Our Team as a System Integrator</b></span>
                        </h3>
                        <p>
                            At ADISRA-THETAVEGA, we value partnerships, innovation, and growth. If you are passionate about technology, automation, and creating solutions that drive success, we invite you to become a part of our esteemed System Integrator community.

                        </p>
                    </div>
                    <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <img src={occupancey} alt="loading..." style={{ height: "20rem", width: '90%' }} />
                    </div>
                </div>
            </div>

            <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
            ><b>Why Choose ADISRA-THETAVEGA</b></h1>


            <div className="row" style={{ paddingTop: "1rem" }}>
                {/* <h2 style={{ color: "rgb(255,102,0)", textAlign: "center", fontSize: "bolder" }} data-aos="fade-down">Theta (θ):</h2> */}
                <div className="col-md-3 p-3 " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card' style={{ height: "12rem" }} >
                        <h3 className="text-center" style={{ color: "rgb(255,102,0)" }} >Cutting-Edge Technology </h3>
                        <p>Partner with a company at the forefront of the industry, offering innovative solutions and tools.</p>
                    </div>
                </div>
                <div className="col-md-3 p-3 " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card' style={{ height: "12rem" }} >
                        <h3 className="text-center" style={{ color: "rgb(255,102,0)" }}>Global Reach</h3>
                        <p className="text-center">Gain access to a worldwide ecosystem and tap into opportunities on a global scale.</p>
                    </div>
                </div>
                <div className="col-md-3 p-3" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card' style={{ height: "12rem" }} >
                        <h3 className="text-center" style={{ color: "rgb(255,102,0)" }}>Support and Resources</h3>
                        <p className="text-center">Benefit from comprehensive support, training, and early access to product releases.</p>
                    </div>
                </div>
                <div className="col-md-3 p-3" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card' style={{ height: "12rem" }} >
                        <h3 className="text-center" style={{ color: "rgb(255,102,0)" }}>Collaboration</h3>
                        <p className="text-center">Join a community of like-minded professionals and collaborate on projects that matter.</p>
                    </div>
                </div>
            </div>

            <h2 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
            ><b> How to Get Started: </b></h2>


            <div className="row mt-4">
                <div className="col-11">
                    <p className="text-justify">
                        To embark on this exciting journey as an ADISRA-THETAVEGA System Integrator, simply Click here to apply for Our System Integrator Application Page. We look forward to welcoming you as a valued member of our team and working together to achieve success.
                        For any inquiries or further information, please contact us at sales@thetavega.tech
                        Join us in shaping the future of automation and technology. Together, we can make a difference.

                    </p>
                </div>
            </div>

        </div>
    );
};


export default SystemIntegrator
