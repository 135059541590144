import React from 'react'
import { Outlet } from 'react-router-dom'
// import Slides from './Component/Slides'
import { useEffect } from 'react'
import ReactGA from 'react-ga'

const Maincomp = () => {

useEffect(() => {
  ReactGA.pageview(window.location.pathname)
}, []);
  
  return (
    <div>
      <Outlet />
    </div>
  )
}

export default Maincomp
