import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import bannermain from '../Industries/ENERGY AND GAS/banner.png'
import assetmonitor from '../Industries/ENERGY AND GAS/assetmonitor.jpg'
import demandresponce from '../Industries/ENERGY AND GAS/demandresponce.png'
import emergency from '../Industries/ENERGY AND GAS/Emergency Response Systems.jpg'
import energyeffi from '../Industries/ENERGY AND GAS/energyeffi.jpg'
import gasleak from '../Industries/ENERGY AND GAS/gasleak.jpg'
import pipemoniter from '../Industries/ENERGY AND GAS/pipemonitoring.avif'
import regulatorycomp from '../Industries/ENERGY AND GAS/regulatorycomp.jpg'
import predictive from '../Industries/ENERGY AND GAS/predictive.avif'
import renewableenergy from '../Industries/ENERGY AND GAS/renewableenergy.avif'
import smartgridplan from '../Industries/ENERGY AND GAS/smartgridautomation.jpg'
import remoteop from '../Industries/ENERGY AND GAS/remote-op-main.avif'
import demand from '../Industries/chages/demantresponse.webp'
import resuletory from '../Industries/chages/complianceReport.jpg'
import leakgas from '../Industries/chages/gasleakdet.avif'






const EnergyAndGas = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);


	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	}, []);

	return (
		<div className="container-fluid" style={{ overflow: "hidden" }}>
			{/* <div className="bg0">
				<p className="col-md-12" >
					<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Autonomous Machine </NavLink>
				</p>
			</div> */}

			<header className="" style={{ marginTop: "" }}>
				<img src={bannermain} alt="Logo" className='aboutimg' />

				<div className="container">
					<div className="row">
						<div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>In the energy and gas industries, where reliability, safety, and efficiency are of utmost importance, our Automation, Digitization and Traceability solutions are engineered to optimize operations, enhance asset performance, and ensure regulatory compliance. We offer a comprehensive suite of solutions tailored to address the unique challenges of these vital sectors:
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={smartgridplan} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Smart Grid Automation </b></span>
						</h3>
						<p>
							Implement intelligent automation solutions for electricity distribution and Management. OUr solutions enable real-time monitoring and control of grid components, optimizing energy disribution and reducing outages.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Renewable Energy Management </b></span>
						</h3>
						<p>
							Monitor and control renewable energy sources such as solar panels and wind turbines through automation. Maximize energy generation, minimize downtime, and feed surplus energy back into the grid.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={renewableenergy} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={predictive} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Predictive Maintenance</b></span>
						</h3>
						<p>
							Utilize predictive maintenance solutions to monitor the health of power generation equipment. By analysing data from sensors and historical performance, our systems predict potential failures and recommend maintenance actions

						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Energy Efficiency Optimization</b></span>
						</h3>
						<p>
							Employ automation to optimize energy usage within buildings, industrial facilities, and commercial spaces. Our solutions integrate with HVAC systems, lighting, and other energy-consuming systems to minimize wastage and reduce operational costs.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={energyeffi} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={demand} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Demand Response Management</b></span>
						</h3>
						<p>
							Enable demand response programs by implementing automated systems that adjust energy consumption based on grid conditions and pricing. Contribute to grid stability while benefiting from cost savings.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Pipeline Monitoring and Control</b></span>
						</h3>
						<p>
							Implement remote monitoring and control solutions for gas pipelines. Our systems ensure optimal pressure, detect leaks, and prevent unauthorized access, enhancing safety and minimizing environmental impact.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={pipemoniter} alt="loading..."  />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={leakgas} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Gas Leak Detection</b></span>
						</h3>
						<p>
							: Utilize sensor-based automation to detect gas leaks in real-time. Promptly identify and mitigate leaks to ensure safety, prevent accidents, and minimize gas losses.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Asset Management and Condition Monitoring</b></span>
						</h3>
						<p>
							Monitor the condition of critical assets such as compressors, pumps, and valves. Predictive analytics and real-time monitoring help prevent equipment failures, optimize maintenance, and extend asset lifetimes.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={assetmonitor} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={resuletory} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Regulatory Compliance and Reporting</b></span>
						</h3>
						<p>
							Digitize compliance processes and reporting to ensure adherence to industry regulations. Our solutions provide accurate records and documentation for audits and regulatory submissions.
						</p>
					</div>
				</div>
			</div>


			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b> Emergency Response Systems</b></span>
						</h3>
						<p>
							Implement automated emergency response systems that activate safety protocols in case of gas leaks, fires, or other critical incidents. Swift response minimizes potential damage and ensures personnel safety.

						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={emergency} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={remoteop} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Remote Operations and Maintenance</b></span>
						</h3>
						<p>
							Gain remote access to gas infrastructure for monitoring and maintenance. This capability allows for real-time assessment, troubleshooting, and preventive actions, reducing the need for physical presence at remote sites.
						</p>
					</div>
				</div>
			</div>







			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-10 mt-5" data-aos="zoom-in-left">
						<p>
							At Thetavega, we understand the complexities of the energy and gas industries. Our Automation, Digitization and Traceability solutions are designed to enhance reliability, safety, and operational efficiency while ensuring compliance with industry standards. Partner with us to drive the new era of innovation and excellence in the energy and gas sectors.
						</p>
					</div>
				</div>
				{/* <NavLink to="/industries/pharmaceuticals" className="" style={{float:"right",color:"rgb(255,102,0)",fontSize:"1.2rem", marginTop:"2rem",marginRight:"5rem" }} data-aos="zoom-in-left">Explore to Pharmaceuticals
		</NavLink> */}
			</div>

		</div>
	);
};


export default EnergyAndGas
