import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


import occupancey from '../img2/join us fostering.avif'
import smartbuilding from '../img2/join.avif'





const Programsadi = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);


    useEffect(() => {
        AOS.init(); // Initialize the AOS library
    }, []);

    return (
        <div className="container-fluid" style={{ overflow: "hidden" }}>


            <header className="" style={{ marginTop: "1rem" }}>
               
            </header>
            {/* --------------------------------------SECTION----------------------------------------------------- */}
            <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "6rem" }} data-aos="fade-down"
            ><b>Educational Program: </b></h1>
            <div className="container dataparts">
                <div className="row paragraph1 mt-5">

                    <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
                        <img src={smartbuilding} alt="loading..." style={{ height: "22rem", width: '80%' }} />
                    </div>
                    <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Join Us in Fostering Innovation</b></span>
                        </h3>
                        <p>
                            At ADISRA-THETAVEGA, our passion for education is at the heart of what we do. We believe that the exchange of knowledge is a powerful catalyst for progress. That's why we are eager to collaborate with educational institutions and research groups.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row  paragraph1">

                    <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <h3 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Our Offer</b></span>
                        </h3>
                        <p>
                            We are excited to extend an invitation to educational institutions and research groups to partner with us. By joining our educational program, you gain access to complimentary educational licenses and comprehensive training on our software.
                        </p>
                    </div>
                    <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
                        <img src={occupancey} alt="loading..." style={{ height: "20rem", width: '90%' }} />
                    </div>
                </div>
            </div>

            <div className="container dataparts">
                <div className="row paragraph1 mt-5">

                    <div className="col-md-10" data-aos="zoom-in-right" style={{ margin: "auto" }} >
                        <h1 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Our Shared Goals</b></span>
                        </h1>
                        <p>
                            Our primary objective is to create a collaborative environment where both parties can learn from one another. We aim to:
                        </p>
                    </div>
                </div>
            </div>

            <div className="row" style={{ paddingTop: "1rem", margin: "2rem" }}>

                <div className="col-md-3 p-3 " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card' style={{ height: "13rem" }} >
                        <h3 className="text-center">Promote Innovation </h3>
                        <p className="text-center">Together, we can explore new ideas and drive innovation in the field of industrial automation.</p>
                    </div>
                </div>
                <div className="col-md-3 p-3 " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card' style={{ height: "13rem" }} >
                        <h3 className="text-center">Support the Next Generation</h3>
                        <p className="text-center">By offering educational resources, we contribute to the development of the next generation of automation experts</p>
                    </div>
                </div>
                <div className="col-md-3 p-3" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card' style={{ height: "13rem" }} >
                        <h3 className="text-center">Mutual Exchange</h3>
                        <p className="text-center">Our partnership is built on a foundation of mutual learning and growth.</p>
                    </div>
                </div>

            </div>

            <div className="container dataparts">
                <div className="row paragraph1 mt-5">

       
                    <div className="col-md-10" data-aos="zoom-in-right" style={{ margin: "auto" }} >
                        <h1 className="b11">
                            <span style={{ color: "rgb(255,102,0)" }}><b>Get In Touch</b></span>
                        </h1>
                        <p>
                            If you are an educational institution or a research group eager to collaborate, please reach out to us at info@thetavega.tech. We are excited to embark on this journey of knowledge sharing and innovation with you.
                            Join us in shaping the future of industrial automation through education and collaboration. Together, we can make a meaningful impact on the world of technology and automation.

                        </p>
                    </div>
                </div>
            </div>






        </div>
    );
};



export default Programsadi