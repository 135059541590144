import React from "react";
import "../About.css";
import { NavLink } from 'react-router-dom';
import logo1 from "../autonomus_photo/Autonomous Machines.png";
import logo2 from "../autonomus_photo/Consulting.avif";
import logo3 from "../autonomus_photo/Manufacturing.jpg";
import logo4 from "../autonomus_photo/Software Development.avif";
import logo5 from "../autonomus_photo/Machine_Integration.avif";
import logo6 from "../autonomus_photo/Mainch_Maintainance.avif";
import logo7 from "../autonomus_photo/Machine_monitoring.avif";
import logo8 from "../autonomus_photo/Safety.avif";
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

    const style = {
 
      // Adding media query..
      'only screen and (max-width: 576px)': {
        display:'none'
      },
    };

const Autonomous = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);

	
	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

	return (
		<div className="container-fluid" style={{overflow:"hidden"}}>
			<div className="bg0">
				<p className="col-md-12" >
					<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Autonomous Machine </NavLink>
				</p>
			</div>

			<header className="">
				<img src={logo1} alt="Logo" className='aboutimg mt-0' />
			
				<div className="container about-containt ">
					<div className="row">
						<div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}>Autonomous machines</span> are machines that can operate and make decisions without human intervention. These machines use sensors, artificial intelligence, and machine learning algorithms to perceive their environment, analyse data, and make decisions. We Offer related to autonomous machines that businesses can leverage to improve their operations. Here are some of our common offerings for Autonomous Machines Development:
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<div className="container dataparts about-containt ">
				<div className="row paragraph1 mt-5">
					
					<div className="col-md-5" data-aos="zoom-in-right">
						<img src={logo2} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" >
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Consulting</b></span>
					</h3>
						<p>
						We Provide Autonomous machine consulting services that include strategic guidance to businesses looking to implement autonomous machines. Consultants can help businesses identify the right technologies and solutions that can improve business processes and create new opportunities.
						</p>
					</div>
				</div>
			</div>

			<div className="container about-containt odr">
				<div className="row  paragraph1">
					
					<div className="col-md-5" data-aos="zoom-in-left">
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Machine Manufacturing</b></span>
					</h3>
						<p>
						We Provide Complete End-to-End Solutions for Designing and development from Concept to Commissioning Services for Building Autonomous Machines and integrating them into your existing Manufacturing Setup.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left">
						<img src={logo3} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container about-containt">
				<div className="row paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right">
						<img src={logo4} alt="loading..."  />
					</div>
					<div className="col-md-5"  data-aos="zoom-in-right">
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Software Development</b></span>
					</h3>
						<p>
						We provide customized software solutions that enable machines to operate autonomously. This includes developing algorithms, control systems, and machine learning models.
						</p>
					</div>
				</div>
			</div>

			<div className="container about-containt" >
				<div className="row  paragraph1" >
				
					<div className="col-md-5"   data-aos="zoom-in-left">
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Machine Integration</b></span>
					</h3>
						<p>
						We Provide services to help businesses integrate autonomous machines with existing systems and processes. This includes integrating autonomous machines with enterprise software, cloud platforms, and other third-party systems.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left">
						<img src={logo5} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container about-containt">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right">
						<img src={logo6} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right">
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Machine Maintenance</b></span>
					</h3>
						<p>
						We provide regular maintenance and repair for autonomous machines. This includes software updates, hardware upgrades, and troubleshooting.
						</p>
					</div>
				</div>
			</div>

			<div className="container about-containt odr ">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-left">
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Machine Monitoring</b></span>
					</h3>
						<p>
						We Provide services that enable businesses to monitor the performance and health of autonomous machines in real time. This includes data analytics, predictive maintenance, and remote monitoring.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left">
						<img src={logo7} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container about-containt">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right">
						<img src={logo8} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right">
						<br />
						<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Machine Security</b></span>
					</h3>
						<p>
						We provide solutions to protect autonomous machines, networks, and data from cyber threats. This includes secure machine authentication, data encryption, and secure network connections.
						</p>
					</div>
				</div>
			</div>
					
			<div className="container about-containt">
				<div className="row  paragraph1">
					<div className="col-md-11 mt-5" data-aos="zoom-in-left">
						<p>
						Collectively, we businesses with the tools and expertise needed to harness the power of autonomous machines and transform their operations <NavLink to="/contact" >Get in touch with us for more Details.</NavLink>
						</p>
					</div>
				</div>
				<NavLink to="/integrations" className="mt-5" style={{float:"right",color:"rgb(255,102,0)"}} data-aos="zoom-in-left"><p>Explore system Integration</p>
		</NavLink>
			</div>

		</div>
	);
};

export default Autonomous;

