import React from 'react';
import './Niche.css';
import { NavLink } from 'react-router-dom';
import logo6 from '../imgs/img_copy.svg';

const Niche = () => {
  return (
    <div className='header1'>
        <hr className='hrtag' />
      <h2 >
        <h1 style={{ textAlign: "center",fontStyle:"bold", marginTop: "50px" }} data-aos="fade-down">Empowering Through Expertise</h1>
      </h2>
      <p style={{ textAlign: "center" , color:"rgb(255,102,0)" }} data-aos="fade-down">Explore Our Core Solutions Driving Success and Innovation.</p>
    


      <section className='our' data-aos="zoom-in">
        <div className='row'>
          
          <div className='col-md-8 col-sm-9 track'>
            <h3 className='track1' style={{ textAlign: "left" ,fontFamily:"Courier New"}}>
              <span style={{ color: 'red'}}>&gt;</span> track n{' '}
              <span style={{ color: 'red' ,fontFamily:"Courier New" }}>trace</span>
            </h3>
            <p style={{textAlign:"justify"}}>
              <span style={{textAlign:"justify", color:"#0A1172"}}>Seamless Visibility and Control: Navigating Manufacturing Excellence with Traceability Systems</span>
              <br />
              <br/>
              To increase efficiency, creativity, and success through data-driven decision-making and streamlined processes, modern manufacturing utilizes integrated traceability systems, including AIDC, Machine Vision, Machine-to-Machine Communication, Remote Monitoring, SPC Analytics, and Cloud Integration.
              <br />
              <br />
                            
              What is the result? A Manufacturing ecosystem dedicated to efficiency, innovation and Sustainability.
              <br />
              <br />
              
              <NavLink to='/trace' className='navbutton'>Explore</NavLink> Our offerings for Complete Traceability Solutions: Unlock the Power of End-to-End Implementation.
     
              
             
       
            </p>
          </div>
          <div className='col-md-2 col-sm-2 mt-5 animat ms-3 hide-on-mobile'>
            <img src={logo6} alt='loading...' style={{ height: '100%', width: '100%' }} />
          </div>
        </div>
      </section>
      <hr/>
    </div>
  );
};

export default Niche;
