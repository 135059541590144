import { colors } from "@mui/material";
import ScrollToTop from "react-scroll-to-top";

function GoToBtn() {
  return (
    <div>
      
      <div style={{ marginTop: "15vh", color:"red" }} />
      <ScrollToTop smooth />
    </div>
  );
}
export default GoToBtn;