import React from "react";
import "../About.css";
import { NavLink } from "react-router-dom";
import { useEffect } from 'react';
import logo1 from "../BIG DATA/banner.png"
import logo2 from "../BIG DATA/big data consulting.png"
import logo3 from "../BIG DATA/data-warehouse.svg"
import logo4 from "../BIG DATA/data-integrationr.jpg"
import logo5 from "../BIG DATA/dATA_vISUALIZATION.avif"
import logo6 from "../BIG DATA/MAchine Learning & AI.avif"
import logo7 from "../BIG DATA/Data_secirity.avif"
import AOS from 'aos';
import 'aos/dist/aos.css';




const BigData = () => {

	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  return (
    <div className="container-fluid" style={{overflow:"hidden"}}>
		<div className="bg0">
		  <p className="col-md-12" >
			<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Big Data </NavLink>
		  </p>
		</div>
  
		<header className="">
		  <img src={logo1} alt="Logo" className='aboutimg mt-0'  />
		
		  <div className="container">
			<div className="row">
			  <div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
				<p>
				  <span style={{ color: "rgb(255,102,0)" }}>Big data analytics</span> is the process of examining large and complex data sets to uncover hidden patterns, correlations, and other useful information that can help businesses make informed decisions. Our Big data analytics offerings can vary depending on the specific needs of the business. Here are some of our common offerings for Big data analytics:


				</p>
			  </div>
			</div>
		  </div>
		</header>
		{/* --------------------------------------SECTION----------------------------------------------------- */}
		<div className="container dataparts">
		  <div className="row paragraph1 mt-5">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo2} alt="loading..." />
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Consulting</b></span>
			  </h3>
			  <p>
			  We Provide Big data consulting services to businesses with guidance on how to design and implement big data analytics solutions. This includes defining business requirements, identifying data sources, selecting the right technologies and tools, and creating a roadmap for implementation.
			  </p>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Data Warehousing</b></span>
			  </h3>
			  <p>
			  Data warehousing is the process of consolidating data from multiple sources into a single, central repository for analysis. Our Data warehousing services provide businesses with a centralized storage solution that can scale to meet their needs.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo3} alt="loading..."/>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo4} alt="loading..."/>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Data Integration</b></span>
			  </h3>
			  <p>
			  We Provide Data integration services to help businesses combine data from different sources into a single, unified view. This includes integrating data from on premise systems, cloud-based applications, and external data sources.
			  </p>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Data Visualization</b></span>
			  </h3>
			  <p>
			  We Provide Data visualization services to help businesses create interactive dashboards and reports that enable users to explore and analyse data in real time. This includes creating charts, graphs, and other visualizations that provide insights into data trends and patterns.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo5} alt="loading..."/>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo6} alt="loading..."/>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Machine Learning and AI</b></span>
			  </h3>
			  <p>
			  We Provide Machine learning and Artificial Intelligence services to help businesses leverage advanced algorithms to uncover insights from their data. This includes creating predictive models that can be used to forecast trends and patterns and to automate business processes.
			  </p>
			</div>
		  </div>
		</div>
		<div className="container">
		  <div className="row  paragraph1">
  
			
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Data Security</b></span>
			  </h3>
			  <p>
			  We Provide Data security services help to businesses protect their data from unauthorized access, theft, or other cyber threats. This includes implementing data encryption, access controls, and other security measures to ensure the integrity and confidentiality of data.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo7} alt="loading..." />
			</div>
		  </div>
		</div>
	

  
  
		<div className="container">
		  <div className="row  paragraph1">
			<div className="col-md-11 mt-5" data-aos="zoom-in-left">
			  <p>
			  Collectively, we provide businesses with the tools and expertise they need to turn their data into actionable insights, enabling them to make better decisions, optimize their operations, and create new opportunities.<NavLink to="/contact" > Get in touch with us for more Details.</NavLink>
			  </p>
			</div>
		  </div>
		  <NavLink to="/arvr" className="" style={{float:"right",color:"rgb(255,102,0)"}} data-aos="zoom-in-left"><p>Explore AR & VR Solutions</p>
		</NavLink>
		</div>
  
	  </div>
  );
};

export default BigData;