import React from "react";
import { useEffect } from 'react';

import "./CloudComputing.css";
import { NavLink } from 'react-router-dom';
import logo1 from "../CLOUD COMPUTING/banner.png";
import logo2 from "../CLOUD COMPUTING/-cloud-application-development.webp";
import logo3 from "../CLOUD COMPUTING/Cloud-based IoT Device Management.svg";
import logo4 from "../CLOUD COMPUTING/Cloud-based IoT Integration.png";
import logo5 from "../CLOUD COMPUTING/Cloud-based IoT Platform Development.png";
import logo6 from "../CLOUD COMPUTING/Cloud based AI ML.png";
import logo7 from "../CLOUD COMPUTING/Cloud-based Big Data Analytics.jpg";
import logo8 from "../CLOUD COMPUTING/Cloud-based Cybersecurity.avif";
import logo from "../img3/cloud computing.jpg";
import AOS from 'aos';
import 'aos/dist/aos.css';



const CloudComputing = () => {

	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	},[]);
	return (
		<div className="container-fluid" style={{overflow:"hidden"}}>
		<div className="bg0">
		  <p className="col-md-12" >
			<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Cloud Computing </NavLink>
		  </p>
		</div>
  
		<header className="">
		  <img src={logo1} alt="Logo" className='aboutimg mt-0'  />
		 
		  <div className="container" >
			<div className="row">
			  <div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
				<p>
				  <span style={{ color: "rgb(255,102,0)" }}>Cloud Computing  </span>The fourth industrial revolution, known as industry 4.0, is characterized by the integration of cutting-edge technology like IOT, AI into production procedures industry 4.0 relies heavily on cloud computing since it provides a scalable and adaptable infrastructure to enable these solution.<br/><br/> Here are few our main cloud computing services: 


				</p>
			  </div>
			</div>
		  </div>
		</header>
		{/* --------------------------------------SECTION----------------------------------------------------- */}
		<div className="container dataparts">
		  <div className="row paragraph1 mt-5">
  
			<div className="col-md-5" data-aos="zoom-in-left" >
			  <img src={logo2} alt="loading..."/>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Application Development</b></span>
			  </h3>
			  <p>
			  We Provide Cloud-based application development services to create custom applications that run on cloud infrastructure and are designed to meet the specific needs of Industry 4.0 solutions. These applications can automate processes, enable remote control, and provide real-time data visualization.</p>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>IoT Device Management</b></span>
			  </h3>
			  <p>
			  We Provide Cloud-based IoT device management services that offer device provisioning, monitoring, and maintenance solutions. This service helps ensure that devices are secure, reliable, and always connected to the network.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo3} alt="loading..."/>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo4} alt="loading..."/>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>IoT Integration</b></span>
			  </h3>
			  <p>
			  We offer Cloud-based IoT integration services that help businesses integrate IoT solutions with existing systems and processes hosted in the cloud. This includes integrating IoT devices with enterprise software, cloud platforms, and other third-party systems.
			  </p>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>IoT Platform Development</b></span>
			  </h3>
			  <p>
			  A cloud-based IoT platform is a software framework that enables businesses to manage, monitor, and control IoT devices and data. We Provide Cloud-based IoT platform development services that provide customized solutions that meet specific business needs and can be scaled as the business grows.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo5} alt="loading..." />
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo6} alt="loading..."/>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11" >
				<span style={{ color: "rgb(255,102,0)" }}><b>AI and ML Development</b></span>
			  </h3>
			  <p>
			  We Provide Cloud-based AI and ML development services that help businesses develop and deploy AI and ML models in the cloud. This includes developing and integrating machine learning models, natural language processing, and computer vision algorithms.
			  </p>
			</div>
		  </div>
		</div>
		<div className="container">
		  <div className="row  paragraph1">
  
			
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Big Data Analytics</b></span>
			  </h3>
			  <p>
			  We Provide Cloud-based big data analytics services that help businesses process and analyse large amounts of data collected from Industry 4.0 solutions. This includes data processing, data analysis, and data visualization in the cloud, allowing businesses to scale their analytics capabilities to meet their needs.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo7} alt="loading..."  />
			</div>
		  </div>
		</div>
		<div className="container">
		  <div className="row  paragraph1">
		  <div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo8} alt="loading..." />
			</div>
			
			<div className="col-md-5" data-aos="zoom-in-right" >
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Cybersecurity</b></span>
			  </h3>
			  <p>
			  We Provide Cloud-based cybersecurity services that provide solutions to protect Industry 4.0 systems, networks, and data from cyber threats. This includes secure authentication, data encryption, and secure network connections in the cloud.
			  </p>
			</div>
			
		  </div>
		</div>

  
  
		<div className="container">
		  <div className="row  paragraph1">
			<div className="col-md-10 mt-5" data-aos="zoom-in-left">
			  <p>
			  Collectively, we provide businesses with the infrastructure and expertise needed to leverage the power of cloud computing and Industry 4.0 technologies together, enabling them to improve their operations and create new opportunities.<NavLink to="/contact" > Get in touch with us for more Details.</NavLink>
			  </p>
			</div>
		  </div>
		  <NavLink to="/bigdata" className="" style={{float:"right",color:"rgb(255,102,0)"}} data-aos="zoom-in-left"><p>Explore Big Data</p>
		</NavLink>
		</div>
  
	  </div>
	);
};
export default CloudComputing;






