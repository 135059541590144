import React, { useState } from "react";
import logo from "../logo.svg";
import { NavLink } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Navbar.css';
import beforeLogo from '../beforeLogo.svg';

const Navbar = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);

    const toggleNavbar = () => {
        setNavbarOpen(!navbarOpen);
    };

    const closeNavbar = () => {
        setNavbarOpen(false);
    };
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const closeDropdown = () => {
        setIsOpen(false);
    };

    return (
        <>
            <div className="fixed-top nav5">
                <div className="row" id="trans">
                    <div className="col-12 mx-auto">
                        <nav className="navbar navbar-expand-lg navbar-light bg-light">
                            <NavLink onClick={closeNavbar} className="navbar-brand mt-n3 mb-n1" to="/">
                                <img src={beforeLogo} alt="not found" className="beforelogo" />
                                <img src={logo} alt="not found" className="logo" />
                            </NavLink>
                            <button
                                className="navbar-toggler"
                                type="button"
                                onClick={toggleNavbar}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>

                            <div className={`collapse navbar-collapse ${navbarOpen ? 'show' : ''}`} id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto" id="navItems">
                                    <li className="nav-item">
                                        <NavLink onClick={closeNavbar} className="nav-link text-dark" to="/">
                                            Home
                                        </NavLink>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <NavLink
                                            className="nav-link dropdown-toggle"
                                            to="#"
                                            id="navbarDropdownAbout"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            About
                                        </NavLink>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdownAbout">
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/about">
                                                About Us
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/innovation">
                                                Innovations
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/infrastructure">
                                                Infrastructure
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/Skills">
                                                Skills
                                            </NavLink>
                                        </div>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <NavLink
                                            className="nav-link dropdown-toggle"
                                            to="#"
                                            id="navbarDropdownIndustries"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Industries
                                        </NavLink>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdownIndustries">
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/industries/automotiveandancillaries">
                                                Automotive & Ancillaries
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/industries/buildingsandsmartcity">
                                                Buildings & Smart City
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/industries/pharmaceuticals">
                                                Pharmaceuticals
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/industries/textile">
                                                Textile
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/industries/homeapplinces">
                                                Home Appliances
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/industries/steelandmining">
                                                Steel & Mining
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/industries/energyandgas">
                                                Energy & Gas
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/industries/agriculture">
                                                Agriculture
                                            </NavLink>
                                        </div>
                                    </li>



                                    <li className="nav-item dropdown">
                                        <NavLink

                                            className="nav-link dropdown-toggle"
                                            to="#"
                                            id="navbarDropdownProducts"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Products
                                        </NavLink>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdownProducts">
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/products/operatexmes">
                                                OperateX – MES
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/products/stepwiseconnect">
                                                Stepwise Connect
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/products/nexabridge">
                                                NexaBridge IoT
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/products/panelexelectrical">
                                                Panel Ex- Electrical Panels
                                            </NavLink>
                                        </div>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink onClick={closeNavbar} className="nav-link text-dark" to="/capabilities">
                                            Capabilities
                                        </NavLink>
                                    </li>

                                    <li className="nav-item dropdown">
                                        <NavLink

                                            className="nav-link dropdown-toggle"
                                            to="#"
                                            id="navbarDropdownAdisra"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Adisra-Thetavega
                                        </NavLink>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdownAdisra">
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/adisra/product">
                                                Project Strategy
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/adisra/standards">
                                                Standards
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/adisra/building">
                                                Building Blocks
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/adisra/worldwide">
                                                Worldwide Acclaim
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/adisra/supportanddemo">
                                                Support & Demo
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/adisra/systemintegrator">
                                                System Integrator
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/adisra/programs">
                                                Educational Program
                                            </NavLink>
                                            <NavLink onClick={closeNavbar} className="dropdown-item" to="/adisra/about">
                                                About-Adisra
                                            </NavLink>
                                        </div>
                                    </li>

                                    <li className="nav-item">
                                        <NavLink onClick={closeNavbar} className="nav-link text-dark" to="/contact">
                                            Contact Us
                                        </NavLink>
                                    </li>




                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;
