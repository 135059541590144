import React from "react";
import "../About.css"
import { NavLink } from 'react-router-dom';
import logo1 from "../system integration/System Integration Banner.png"
import logo2 from "../system integration/Consultancy.avif"
import logo3 from "../system integration/IoT Integration.svg"
import logo4 from "../system integration/AI_ML_Integration.avif"
import logo5 from "../system integration/Cyber_Security.png"
import logo7 from "../system integration/digital-twin-banner-foreground.png"
import logo6 from "../img3/img13.jpg"
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';



const Integrations = () => {

	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);
	return (
		<div className="container-fluid" style={{overflow:"hidden"}}>
		<div className="bg0">
		  <p className="col-md-12" >
			<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> System Integrations </NavLink>
		  </p>
		</div>
  
		<div className="">
		  <img src={logo1} alt="Logo" className='aboutimg mt-0'/>
		 
		  <div className="container">
			<div className="row">
			  <div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
				<p>
				  <span style={{ color: "rgb(255,102,0)" }}>System Integration </span> Industry 4.0 is the fourth industrial revolution, characterized by the integration of advanced technologies such as the Internet of Things (IoT), Artificial Intelligence (AI), and Machine Learning (ML) into the Manufacturing process. System integration is a critical component of Industry 4.0, as it enables the integration of different systems and technologies into a single, cohesive ecosystem. Here are some of our common offerings for System Integration:

				</p>
			  </div>
			</div>
		  </div>
		</div>
		{/* --------------------------------------SECTION----------------------------------------------------- */}
		<div className="container dataparts">
		  <div className="row paragraph1 mt-5">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo2} alt="loading..."/>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Digital Transformation Consulting</b></span>
			  </h3>
			  <p>
			  We Provide Digital transformation consulting services provide strategic guidance to businesses looking to adopt Industry 4.0 technologies. We can help businesses identify the right technologies and solutions that can improve business processes and create new opportunities.
			  </p>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>IoT Integration</b></span>
			  </h3>
			  <p>
			  We help IoT integration services businesses integrate IoT solutions with existing systems and processes. This includes integrating IoT devices with enterprise software, cloud platforms, and other third-party systems.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo3} alt="loading..." />
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo4} alt="loading..."  />
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>AI and ML Integration</b></span>
			  </h3>
			  <p>
			  We Provide AI and ML integration services that help businesses integrate AI and ML technologies into their systems and processes. This includes developing and integrating machine learning models, natural language processing, and computer vision algorithms.
			  </p>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Cybersecurity Integration</b></span>
			  </h3>
			  <p>
			  We Provide Cybersecurity integration services to protect Industry 4.0 systems, networks, and data from cyber threats. This includes secure authentication, data encryption, and secure network connections.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo5} alt="loading..." />
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo6} alt="loading..." />
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Cloud Integration</b></span>
			  </h3>
			  <p>
			  We Provide Cloud integration services to help businesses integrate cloud technologies into their systems and processes. This includes integrating cloud platforms, cloud storage, and cloud computing services.
			  </p>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Digital Twin Development</b></span>
			  </h3>
			  <p>
			  We Provide Digital twin development services to create virtual models of physical systems and processes. Digital twins enable businesses to simulate and optimize their operations, identify potential problems, and develop solutions before implementing changes in the physical world.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo7} alt="loading..."  />
			</div>
		  </div>
		</div>
  
  
		<div className="container">
		  <div className="row  paragraph1">
			<div className="col-md-11 mt-5" data-aos="zoom-in-left">
			  <p>
			  Our Overall, system integration Industry 4.0 offerings provide businesses with the tools and expertise needed to transform their operations and take advantage of the latest Industry 4.0 technologies. <NavLink to="/contact" >Get in touch with us for more Details.</NavLink>
			  </p>
			</div>
		  </div>
		  <NavLink to="/iot" className="paragraph1" style={{float:"right",color:"rgb(255,102,0)"}} data-aos="zoom-in-left"><p>Explore Internet Of Things</p>
		</NavLink>
		</div>
  
	  </div>
	);
};

export default Integrations;
