import React from 'react'
import capability from "./imgs/THETAVEGAcapabilities.svg"
import { useEffect } from 'react';
import AOS from 'aos';
import './About.css'
import 'aos/dist/aos.css';
import logo1 from  "./img2/Shop floor Integration.png"
import logo2 from  "./img2/erp and cloud connectivity.jpg"
import logo3 from  "./img2/data driven insights.png"
import logo4 from  "./img2/Efficiency Redefined.avif"
import logo5 from  "./img2/Scalable Solutions.png"
import logo6 from  "./img2/global impact.jpg"

const Capabilities = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);


  useEffect(() => {
    AOS.init(); // Initialize the AOS library
  }, []);
  return (
    <div style={{overflow:"hidden"}}>
      <div data-aos="fade-down">
        <img src={capability} alt="Logo" style={{marginTop:"5rem"}} />
      </div>

      <div>
        <div className="container">

          <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
          ><b>Our Capabilities</b></h1>
         
          
        </div>
        <div className="container " >
          <div className="row mt-5">
            <div className="col-md-3" data-aos="fade-down" >

              <div >
                <h5 className="text-center" style={{ color: "rgb(255,102,0)" }}><b>Precision Automation</b></h5>
                <p class="text-center">Elevate your operations with cutting-edge automation solutions that optimize efficiency and accuracy. From Robotic assembly lines to AI-driven processes, we redefine the way you work.</p>
                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
              </div>

            </div>
            <div className="col-md-3" data-aos="fade-down">

              <div style={{ padding: "5px" }}>
                <h5 class="text-center" style={{ color: "rgb(255,102,0)" }}><b>Digital Excellence</b></h5>
                <p class="text-center">Embrace the future of industry with our expertise in digitization. Integrate IoT and AI seamlessly to unlock real-time insights, enhance decision-making, and drive innovation.</p>
                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}

              </div>
            </div>
            <div className="col-md-3" data-aos="fade-down">

              <div >
                <h5 class="text-center" style={{ color: "rgb(255,102,0)" }}><b>Transparent Traceability</b></h5>
                <p class="text-center">Ensure compliance and accountability across your supply chain with our traceability solutions. From source verification to real-time tracking, gain unprecedented transparency and control.</p>
                {/* <a href="#" class="btn btn-primary">Go somewhere</a> */}
              </div>

            </div>
           
            <div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }} data-aos="fade-up">
              <p>
              At Thetavega, we are dedicated to revolutionizing industries through Automation, Digitization, and Traceability solutions. Our expertise lies in creating innovative systems that drive efficiency, optimize processes, and ensure quality across various sectors. With a commitment to excellence and a deep understanding of cutting-edge technologies, we offer a range of capabilities that empower businesses to thrive in the digital age.
              </p>
            </div>

            


 
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					
					</div>
					<div className="col-md-4" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Shop floor Integration</b></span>
						</h3>
						<p>
						Seamlessly connect your shop floor to your ERP and the Cloud with our expertise. Streamline operations, enhance visibility, and optimize processes.
						</p>
					</div>
          <div className="col-md-4" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={logo1} alt="loading..."  />
				</div>
			</div>


			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={logo2} alt="loading..." />
					</div>
					<div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>ERP and Cloud Connectivity</b></span>
						</h3>
						<p>
						We specialize in bridging the gap between your shop floor and your ERP systems. Unlock real-time insights, data synchronization, and efficiency like never before.
						</p>
					</div>
				</div>
			</div>

    
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

			
					<div className="col-md-4" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Data-Driven Insights</b></span>
						</h3>
						<p>
						Harness the power of integrated data. Our solutions provide actionable insights, informed decision-making, and enhanced operational intelligence.
						</p>
					</div>
          <div className="col-md-4" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={logo3} alt="loading..." />
					</div>
				</div>
			</div>


			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={logo4} alt="loading..."/>
					</div>
					<div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Efficiency Redefined</b></span>
						</h3>
						<p>
						By integrating your shop floor with ERP and the Cloud, we redefine efficiency, minimize manual tasks, and empower your business for the future.
						</p>
					</div>
				</div>
			</div>


			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

				
					<div className="col-md-4" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Scalable Solutions</b></span>
						</h3>
						<p>
						Whether you're a small enterprise or an industry giant, our solutions are scalable to meet your needs, ensuring growth and adaptability.
						</p>
					</div>
          <div className="col-md-4" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={logo5} alt="loading..." />
					</div>
				</div>
			</div>

      <div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={logo6} alt="loading..."  />
					</div>
					<div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Global Impact</b></span>
						</h3>
						<p>
						Our capabilities extend globally, delivering transformative shop floor integration solutions across industries. Join us in revolutionizing your operations.
						</p>
					</div>
				</div>
			</div>

          </div>
          </div>
          </div>
          </div>

          )
}

          export default Capabilities
