import React from 'react'
import logo1 from './imgs/CareersBanner.png'
import { useEffect } from 'react';
import './About.css'

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);
  return (
    <div>
       <header className="" style={{ overflow: "hidden" }}>
            <div className='container-fluid m-0  p-0'>
                <img src={logo1} alt="Logo" className='aboutimg' />

              
                <div className='row'>
                    <div className='col-md-11 mt-2 text-justify'>
                        <p> At Theta-Vega Tech, we believe that innovation is powered by people. We're always on the lookout for exceptional talent to join our team and be a part of shaping the future of technology-driven business transformation. As a premier technological solutions provider, we offer a dynamic and collaborative work environment that fosters creativity, growth, and impact.
                        </p>
                    <h3>Mail your resume on :<a href="#"> info@thetavega.tech</a></h3>
                    </div>
                    </div>
                    </div>
                    </header>
    </div>
  )
}

export default Career