import React from "react";
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../About.css'
import bannermain from '../Products/operatex/banner.png'
import downtime from '../Products/operatex/Downtime Tracking and Analysis.png'
import integration from '../Products/operatex/Integration with Other Systems.avif'
import invmgmt from '../Products/operatex/Inventory Management.avif'
import labour from '../Products/operatex/Labour and Resource Tracking.png'
import operator from '../Products/operatex/operationgidence.jpg'
import performance from '../Products/operatex/performance analysis.avif'
import productdoc from '../Products/operatex/Product Documentation.avif'
import productquality from '../Products/operatex//Product Quality Assurance.avif'
import productionmoni from '../Products/operatex/production-monitoring.avif'
import qualitymgmt from '../Products/operatex/Quality Management.avif'
import regulatory from '../Products/operatex/Regulatory Compliance.png'
import resouces from '../Products/operatex/Resource Allocation.jpg'
import scheduling from '../Products/operatex/SchedulingandSequencing.jpg'
import shopfloor from '../Products/operatex/Shop Floor Safety.avif'
import traceability from '../Products/operatex/traceability.png'
import workorder from '../Products/operatex/workorder.avif'
import jhph from '../Products/operatex/jh ph.avif'
import { NavLink } from 'react-router-dom';






const OperatexMse = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);


	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	}, []);

	return (
		<div className="container-fluid" style={{ overflow: "hidden" }}>
			{/* <div className="bg0">
				<p className="col-md-12" >
					<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Autonomous Machine </NavLink>
				</p>
			</div> */}

			<header className="" style={{ marginTop: "1rem" }}>
				<img src={bannermain} alt="Logo" className='aboutimg' />

				<div className="container">
					<div className="row">
						<div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>Thetavega unveils its state-of-the-art Manufacturing Execution System (MES) for advanced manufacturing. Our MES seamlessly combines efficiency and data, fostering operational excellence in a changing industrial environment.  
								<span style={{color:"orange"}}> Welcome to Thetavega's redesigned manufacturing, where perfection rules.</span> <br/>Our MES system offers a wide range of Modules to streamline and optimize manufacturing operations. Some of our MES key offerings include:

							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={scheduling} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Scheduling and Sequencing</b></span>
						</h3>
						<p>
						Creating production schedules, sequencing tasks, and adjusting plans in response to changing conditions.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Production Monitoring </b></span>
						</h3>
						<p>
						Real-time visibility into production processes, allowing manufacturers to track production progress, machine performance, and overall equipment efficiency.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={productionmoni} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={workorder} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Work Order Management</b></span>
						</h3>
						<p>
						Creating, managing, and tracking work orders, ensuring that production tasks are completed in the correct sequence and on time.

						</p>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Operator Guidance System</b></span>
						</h3>
						<p>
						Equipping operators with step-by-step guidance for seamless task execution and consistent outcomes. Powered by <a href="#"> Stepwise Connect.</a> 
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={operator} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={qualitymgmt} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Quality Management</b></span>
						</h3>
						<p>
						Monitoring product quality through inspections, testing, and data analysis to identify and address quality issues promptly.
						</p>
					</div>
					
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Product Quality Assurance</b></span>
						</h3>
						<p>
						Guaranteeing product excellence through comprehensive quality assurance processes and continuous monitoring.
						</p>
					</div>
				<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={productquality} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={invmgmt} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Inventory Management</b></span>
						</h3>
						<p>
						Tracking raw materials, components, and finished goods in real time to optimize inventory levels, reduce waste, and prevent stockouts.


						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Traceability and Genealogy</b></span>
						</h3>
						<p>
						Recording the history and genealogy of products, including materials used, processes involved, and personnel responsible, which is crucial for compliance and recalls.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={traceability} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={resouces} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Resource Allocation</b></span>
						</h3>
						<p>
						Optimizing the allocation of equipment, personnel, and materials to maximize production efficiency.
						</p>
					</div>
					
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Downtime Tracking and Analysis</b></span>
						</h3>
						<p>
						Identifying causes of downtime and inefficiencies in production, helping to minimize disruptions and improve uptime.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={downtime} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
				</div>
			</div>


			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={performance} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Performance Analytics</b></span>
						</h3>
						<p>
						Providing insights and reports on key performance indicators (KPIs) to support data-driven decision-making.
						</p>
					</div>
				
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Integration with Other Systems</b></span>
						</h3>
						<p>
						Connecting with ERP systems, supply chain software, and other enterprise systems to ensure seamless data flow and coordination.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={integration} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={regulatory} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Regulatory Compliance</b></span>
						</h3>
						<p>
							Ensuring that manufacturing processes adhere to industry regulations and standards, facilitating audits and certifications.

						</p>
					</div>
					
				</div>
			</div>


			<div className="container">
				<div className="row  paragraph1">

					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Labour and Resource Tracking</b></span>
						</h3>
						<p>
							Monitoring workforce activities and resource utilization to optimize labour efficiency and reduce costs.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={labour} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row  paragraph1">
				<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={jhph} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>JH & PM</b></span>
						</h3>
						<p>
						Streamlining job hazard assessments and preventive maintenance procedures to enhance safety and equipment longevity.
						</p>
					</div>
					
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Shop Floor Safety</b></span>
						</h3>
						<p>
							Fostering a safety-first culture with tools to monitor, track, and enforce safety protocols and compliance.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={shopfloor} alt="loading..." style={{ height: "20rem", width: '90%' }}/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={productdoc} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Product Documentation</b></span>
						</h3>
						<p>
							Simplifying documentation processes by providing a centralized repository for critical manufacturing documents.
						</p>
					</div>
				</div>
			</div>



			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-10 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<p>
							Our MES system helps manufacturers improve productivity, reduce errors, enhance quality, and gain better insights into their production processes.
							Experience the transformative power of our MES system and embark on a journey toward elevated productivity, heightened quality, and a brighter manufacturing future. <NavLink to="/contact"  >Contact us for more details</NavLink>

						</p>
					</div>
				</div>
				{/* <NavLink to="/industries/pharmaceuticals" className="" style={{float:"right",color:"rgb(255,102,0)",fontSize:"1.2rem", marginTop:"2rem",marginRight:"5rem" }} data-aos="zoom-in-left">Explore to Pharmaceuticals
		</NavLink> */}
			</div>

		</div>
	);
};



export default OperatexMse
