import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import occupancey from '../img2/global.jpeg'




const Worldwide = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  useEffect(() => {
    AOS.init(); // Initialize the AOS library
  }, []);

  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>

      <header className="" style={{ marginTop: "1rem" }}>
        {/* <img src="" alt="Add banner" className='aboutimg' /> */}

        <div className="container">
          <div className="row">
            <div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
              <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
              >WORLDWIDE ACCLAIM</h1>
              <p>
                <span style={{ color: "rgb(255,102,0)" }}></span> ADISRA's "Low-Code" and "SmartView" Platform are globally recognized for their role in developing SCADA/HMI applications across a wide spectrum of industries. Our solutions have made a significant impact in automotive, oil & gas, machinery, food and beverage, pharmaceutical, building automation, packaging, and more. The innovative technology and flexible scalability we offer cater to customers of all sizes. We provide the full range of functionality found in the most popular HMI/SCADA packages, all at a competitive and reasonable price point that aligns with the economic requirements of small to mid-market customers.
              </p>
            </div>
          </div>
        </div>
      </header>
      {/* --------------------------------------SECTION----------------------------------------------------- */}

      <div className="container dataparts">
        <div className="row paragraph1 mt-5">

          <div className="col-md-4" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={occupancey} alt="add map " style={{ height: "14rem", width: '100%' }} />
          </div>
          <div className="col-md-6" data-aos="zoom-in-right" style={{ margin: "auto" }} >
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Global Presence: </b></span>
            </h3>
            <p>
              ADISRA is a global company with offices in North America, South America, and a network of worldwide distributors spanning North America, Latin America, South America, Europe, and Asia-Pacific. Additionally, our extensive system integrators program has successfully attracted partners from all corners of the world.
            </p>
          </div>
        </div>
      </div>


    </div>
  );
};


export default Worldwide
