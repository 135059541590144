import React, { useState } from 'react';
import logo from "../imgs/QR_CODE.svg"
import logo1 from "../imgs/EYE.svg"
import logo2 from "../imgs/M2M.svg"
import logo3 from "../imgs/STATISTICAL.svg"
import logo4 from  "../imgs/DATA_INTEGRATION.svg"
import logo5 from "../imgs/REMOTE_MONITORING.svg"
import './Element.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';

const Element = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  const [selectedCard, setSelectedCard] = useState(null);

  const handleExploreClick = (url) => {
    window.location.href = url;
  }

  const handleNextClick = () => {
    // navigate to the next page
    console.log("/Autonomous");
  }

  return (
    <>
      <h1 className='track1' style={{ textAlign: "center", textAlign: "center" }} data-aos="fade-down">
        Revolutionizing Your Workflow
        <p style={{ textAlign: "center", color: "rgb(255,102,0)", textAlign: "center" }} data-aos="fade-down"> Discover Our Key Traceability Solution Offerings</p>
      </h1>
      <br/>
      <div className='element'>
        <div className="row justify-content-center">
          <div className="col-sm-2 col-md-2 slide1" data-aos="zoom-in">
            <div className="card1">
              <div className="card-body ajay1">
                <img src={logo} alt="Logo" className="logov" />
                <h6 className="card-title">Automated Identification</h6>
                <NavLink to="/datacapture" className="navbutton">Explore more</NavLink>
                <p className="card-text"></p>
              </div>
            </div>
          </div>

          <div className="col-sm-2 col-md-2 slide1" data-aos="zoom-in">
            <div className="card1">
              <div className="card-body ajay1">
                <img src={logo1} alt="Logo" className="logov" />
                <h6 className="card-title">Machine Vision</h6>
                <NavLink to="/machinevision" className="navbutton">Explore more</NavLink>
                <p className="card-text"></p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-2 slide1" data-aos="zoom-in">
            <div className="card1">
              <div className="card-body ajay1">
                <img src={logo2} alt="Logo" className="logov" />
                <h6 className="card-title"> Machine to Machine Communication</h6>
                <NavLink to="/m2m" className="navbutton">Explore more</NavLink>
                <p className="card-text"></p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-2 slide1" data-aos="zoom-in">
            <div className="card1">
              <div className="card-body ajay1">
                <img src={logo5} alt="Logo" className="logov" />
                <h6 className="card-title">Remote Monitoring</h6>
                <NavLink to="/remotemonitoring" className="navbutton">Explore more</NavLink>
                <p className="card-text"></p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-2 slide1" data-aos="zoom-in">
            <div className="card1">
              <div className="card-body ajay1">
                <img src={logo3} alt="Logo" className="logov" />
                <h6 className="card-title">Statistical Process Control </h6>
                <NavLink to="/statistical" className="navbutton">Explore more</NavLink>
                <p className="card-text"></p>
              </div>
            </div>
          </div>
          <div className="col-sm-2 col-md-2 slide1" data-aos="zoom-in">
            <div className="card1">
              <div className="card-body ajay1">
                <img src={logo4} alt="Logo" className="logov" />
                <h6 className="card-title">Data Integration</h6>
                <NavLink to="/dataintegration" className="navbutton">Explore more</NavLink>
                <p className="card-text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Element;
