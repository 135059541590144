import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


import occupancey from '../img2/leadership.avif'
import smartbuilding from '../img2/adisra_laptop.jpg'






const Aboutadi = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);


  useEffect(() => {
    AOS.init(); // Initialize the AOS library
  }, []);

  return (
    <div className="container-fluid" style={{ overflow: "hidden" }}>


      <header className="" style={{ marginTop: "1rem" }}>
        {/* <img src={bannermain} alt="Logo" className='aboutimg' />

				<div className="container">
					<div className="row">
						<div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>In Building Management, Energy Management, and Smart City initiatives, our Automation, Digitization and Traceability solutions are designed to create intelligent and sustainable urban environments. We offer a comprehensive suite of solutions to address the unique challenges and opportunities in these areas:
							</p>
						</div>
					</div>
				</div> */}
      </header>
      {/* --------------------------------------SECTION----------------------------------------------------- */}
      <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "6rem" }} data-aos="fade-down"
      ></h1>
      <div className="container dataparts">
        <div className="row paragraph1 mt-5">

          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
            <img src={smartbuilding} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
          <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>About ADISRA:</b></span>
            </h3>
            <p>
              Established in 2017, ADISRA was founded with a mission to offer cutting-edge technology to industrial automation customers at an affordable price point. Our unwavering dedication is to provide unparalleled customer service and collaborate closely with our customers to not just meet but exceed their expectations.
            </p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row  paragraph1">

          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <h3 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Experienced Leadership</b></span>
            </h3>
            <p>
            ADISRA, led by seasoned entrepreneurs with 100+ years of collective experience in industrial automation software.Former InduSoft employees who created "InduSoft Web Studio" and former PHI System founders, who were renowned for their superior software and first-rate customer service, are among the company's founders. outstanding performance in the market.
            </p>
          </div>
          <div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
            <img src={occupancey} alt="loading..." style={{ height: "20rem", width: '90%' }} />
          </div>
        </div>
      </div>

      <div className="container dataparts">
        <div className="row paragraph1 mt-5">

          {/* <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={smartbuilding} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div> */}
          <div className="col-md-10" data-aos="zoom-in-right" style={{ margin: "auto" }} >
            <h1 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>Our Ethos</b></span>
            </h1>
            <p>
              ADISRA derives its name from the definition of resilience. Our vision was to create software solutions that are resilient. Resilient software solutions will continue to function when faced with adversity. That is important because no matter how well a system is engineered, reality will sooner or later conspire to disrupt the system. So, software must be aware, diverse, integrated, self-regulating and adaptive. Our software is constantly evolving, improving, and goaled to eliminate downtime, reduce maintenance costs, reduce lost production time, and create new business models for our customers to achieve business success.
            </p>
            <div className="row">
              <div className="col-md-2">
                <div className="row" style={{ justifyContent:"center" }}>
                  <h1 style={{ color: "white", textAlign: "center", backgroundColor: "purple",marginTop:"2rem", width: "3.5rem" }}>A</h1>
                  <h5 style={{ color: "rgb(255,102,0)", textAlign: "center", marginTop:"1rem" }}>AWARE</h5>
                  <p className="text-center text-dark">Software needs to be aware. aware of the securrity risks and to byiild the controls required in sortware to prevent attacks</p>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row" style={{ justifyContent:"center" }}>
                  <h1 style={{ color: "white", backgroundColor: "purple",marginTop:"2rem", width: "3.5rem" }}>D</h1>
                  <h5 style={{ color: "rgb(255,102,0)", textAlign: "center", marginTop:"1rem" }}>DIVERSE</h5>
                  <p className="text-center text-dark">Software needs diversity to facilitate adapting itself to customers requirement or execution environment such as machine learning.</p>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row" style={{ justifyContent:"center" }}>
                  <h1 style={{ color: "white", textAlign: "center", backgroundColor: "purple",marginTop:"2rem", width: "3.5rem" }}>I</h1>
                  <h5 style={{ color: "rgb(255,102,0)", textAlign: "center", marginTop:"1rem" }}>INTEGRATED</h5>
                  <p className="text-center text-dark">Software needs diversity to integrate into the current customer environment whether it is a brown field or a green field</p>
                </div>
              </div>


              <div className="col-md-2">
                <div className="row" style={{ justifyContent:"center" }}>
                  <h1 style={{ color: "white", textAlign: "center", backgroundColor: "purple",marginTop:"2rem", width: "3.5rem" }}>S</h1>
                  <h5 style={{ color: "rgb(255,102,0)", textAlign: "center", marginTop:"1rem" }}>SELF</h5>
                  <p className="text-center text-dark">Software that is self-regulating need to be able to deal with anomalies and disruptions without malfuntion or catastrophes.</p>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row" style={{ justifyContent:"center" }}>
                  <h1 style={{ color: "white", textAlign: "center", backgroundColor: "purple",marginTop:"2rem", width: "3.5rem" }}>R</h1>
                  <h5 style={{ color: "rgb(255,102,0)", textAlign: "center", marginTop:"1rem" }}>REGLATING</h5>
                  <p className="text-center text-dark">Software that is self-regulating need to be able to deal with anomalies and disruptions without malfuntion or catastrophes.</p>
                </div>
              </div>

              <div className="col-md-2">
                <div className="row" style={{ justifyContent:"center" }}>
                  <h1 style={{ color: "white", textAlign: "center", backgroundColor: "purple",marginTop:"2rem", width: "3.5rem" }}>A</h1>
                  <h5 style={{ color: "rgb(255,102,0)", textAlign: "center", marginTop:"1rem" }}>ADAPTIVE</h5>
                  <p className="text-center text-dark">adaptive software mens that it needs to constantly change because we live in a changing environment.</p>
                </div>
              </div>
              
            </div>

          </div>
        </div>
      </div>



      <div className="container dataparts">
        <div className="row paragraph1 mt-5">

          {/* <div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={smartbuilding} alt="loading..." style={{ height: "20rem", width: '90%' }} />
					</div> */}
          <div className="col-md-10" data-aos="zoom-in-right" style={{ margin: "auto" }} >
            <h1 className="b11">
              <span style={{ color: "rgb(255,102,0)" }}><b>ADISRA – THETAVEGA</b></span>
            </h1>
            <p>
              The partnership between ADISRA and THETAVEGA represents a powerful synergy in the realm of technology and automation. By combining ADISRA's expertise in software solutions with THETAVEGA's innovative approach to industrial automation, this collaboration brings forth a dynamic force for progress. Together, we aim to deliver cutting-edge products and services that meet the evolving needs of our customers. This partnership is not just about integration; it's about creating a new standard of excellence in the industry. We are excited to embark on this journey of innovation, and our shared commitment to excellence is the driving force that fuels our collaboration, promising exciting advancements in the world of automation and technology.
            </p>
          </div>
        </div>
      </div>






    </div>
  );
};

export default Aboutadi;
