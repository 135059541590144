import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

import energyef from '../Industries/Automotive&Ancillaries/Energy Efficiency Solutions.avif'
import preductive from '../Industries/Automotive&Ancillaries/Predictive Maintenance.svg'
import bannermain from '../Industries/BMS EMS & SMART CITY/banner.png'
import demand from '../Industries/BMS EMS & SMART CITY/Demand Side Management.jpg'
import envior from '../Industries/BMS EMS & SMART CITY/Environmental Monitorin.avif'
import indoor from '../Industries/BMS EMS & SMART CITY/Indoor Air Quality Monitoring.avif'
import occupancey from '../Industries/BMS EMS & SMART CITY/Occupancy and Space Utilization Monitoring.webp'
import renewable from '../Industries/BMS EMS & SMART CITY/Renewable Energy Integration.avif'
import smartbuilding from '../Industries/BMS EMS & SMART CITY/Smart Building Automation.jpg'
import smartgrid from '../Industries/BMS EMS & SMART CITY/Smart Grid Integration.avif'
import wastemgmtauto from '../Industries/BMS EMS & SMART CITY/Waste Management Automation.jpg'
import energyana from '../Industries/chages/energyanalytics.avif'
import watergr from '../Industries/chages/watergridmgmt.jpg'





const BuildingsAndSmartCity = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);


	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	}, []);

	return (
		<div className="container-fluid" style={{ overflow: "hidden" }}>


			<header className="" style={{ marginTop: "1rem" }}>
				<img src={bannermain} alt="Logo" className='aboutimg' />

				<div className="container">
					<div className="row">
						<div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>In Building Management, Energy Management, and Smart City initiatives, our Automation, Digitization and Traceability solutions are designed to create intelligent and sustainable urban environments. We offer a comprehensive suite of solutions to address the unique challenges and opportunities in these areas:
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Building Management</b></h1>
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={smartbuilding} alt="loading..."  />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Smart Building Automation</b></span>
						</h3>
						<p>
							Implement integrated building management systems to control lighting, HVAC, security, and more. These systems optimize energy consumption, enhance occupant comfort, and improve operational efficiency.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Occupancy and Space Utilization Monitoring</b></span>
						</h3>
						<p>
							Utilize sensors and IoT devices to monitor occupancy and space utilization in buildings. Optimize space allocation, energy usage, and facility management based on real-time data.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={occupancey} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={energyef} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Energy Efficiency Solutions</b></span>
						</h3>
						<p>
							Integrate energy management systems that monitor and control energy consumption in buildings. Implement demand response strategies, schedule energy-intensive tasks during off-peak hours, and track energy usage patterns.

						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Indoor Air Quality Monitoring</b></span>
						</h3>
						<p>
							Deploy sensors to measure indoor air quality parameters such as CO2 levels, humidity, and pollutants. Maintain healthy indoor environments and optimize ventilation systems.</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={indoor} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={preductive} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Predictive Maintenance</b></span>
						</h3>
						<p>
							Utilize IoT sensors and data analytics to predict maintenance needs for building systems and equipment. Prevent equipment failures and reduce downtime through proactive maintenance.
						</p>
					</div>
				</div>
			</div>

			<h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Energy Management</b></h1>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Smart Grid Integration</b></span>
						</h3>
						<p>
							Implement smart grid solutions to optimize energy distribution and demand response. Enable real-time communication between power generation sources, distribution networks, and consumers.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left " style={{ margin: "auto" }}>
						<img src={smartgrid} alt="loading..."  />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={renewable} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Renewable Energy Integration</b></span>
						</h3>
						<p>
							Integrate solar panels, wind turbines, and other renewable energy sources into energy grids. Maximize renewable energy utilization while ensuring grid stability.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Demand Side Management</b></span>
						</h3>
						<p>
							Utilize automation to manage energy demand through smart appliances, electric vehicle charging stations, and real-time pricing adjustments. Balance energy supply and demand effectively.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={demand} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={energyana} alt="loading..." />
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Energy Analytics and Reporting</b></span>
						</h3>
						<p>
							Utilize data analytics to generate insights on energy consumption patterns, peak usage times, and potential savings opportunities. Create detailed reports for informed decision-making.
						</p>
					</div>
				</div>
			</div>


			<h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
			><b>Smart City</b></h1>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Water Grid Management Automation</b></span>
						</h3>
						<p>
						Use automated systems for water treatment and distribution, adjusting processes and chemicals in real-time. SCADA systems collect and analyze data for remote control and informed decisions.

						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={watergr} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={wastemgmtauto} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Waste Management Automation</b></span>
						</h3>
						<p>
						Integrate sensors and data analytics to optimize waste collection routes and schedules. Reduce operational costs and improve waste management efficiency.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">

					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<h3 className="b11">
							<span style={{ color: "rgb(255,102,0)" }}><b>Environmental Monitoring</b></span>
						</h3>
						<p>
						Deploy sensors for monitoring air quality, water quality, noise levels, and other environmental parameters. Ensure a healthier and more sustainable urban environment.

						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={envior} alt="loading..."/>
					</div>
				</div>
			</div>





			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-11 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<p>
						At Thetavega, we recognize the transformative potential of building management, energy management, and smart city solutions. Our Automation Digitization and Traceability offerings are designed to create interconnected, efficient, and liveable urban spaces that benefit residents, businesses, and the environment. Join us in shaping the future of intelligent cities and sustainable energy practices.
						</p>
					</div>
				</div>
			
			</div>

		</div>
	);
};




export default BuildingsAndSmartCity
