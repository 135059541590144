import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import logo from '../Aditheta/building blocks.png'




const Buildingblocks = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
    }, []);


    useEffect(() => {
        AOS.init(); // Initialize the AOS library
    }, []);

    return (
        <div className="container-fluid " style={{ overflow: "hidden" }}>


            <header className="" style={{ margin:"auto" }}>
                <img src={logo} alt="add banner" className='aboutimg1 ' />

                <div className="container">
                    <div className="row">
                        <div className="col-md-10 paragraph1" style={{ marginTop: "1rem" }}>
                            <p>
                                <span style={{ color: "rgb(255,102,0)" }}></span>
                            </p>
                        </div>
                    </div>
                </div>
            </header>
            {/* --------------------------------------SECTION----------------------------------------------------- */}
            <h1 className='text-center heading-ethics ' style={{ color: "rgb(255,102,0)", marginTop: "4rem" }} data-aos="fade-down"
            ><b>Our products serve as modular building blocks</b></h1>

            <div className="row" style={{ padding: "1rem" }}>
                {/* <h2 style={{ color: "rgb(255,102,0)", textAlign: "center", fontSize: "bolder" }} data-aos="fade-down">Theta (θ):</h2> */}
                <div className="col-md-4 p-5 " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card' style={{padding:"2rem"}} >
                        <h3 className="text-center" style={{color:"rgb(255,102,0)"}}>ADISRA-THETAVEGA SmartView </h3>
                        <p className="text-center">Gathers data from various devices and sources, managing equipment and process operations to answer "What is happening now?"</p>
                    </div>
                </div>
                <div className="col-md-4 p-5 " data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card'  style={{padding:"2rem"}}  >
                        <h3 className="text-center" style={{color:"rgb(255,102,0)"}}>InsightView™ </h3>
                        <p className="text-center">Uncovers valuable insights from data analysis to answer "What has happened?"</p>
                    </div>
                </div>
                <div className="col-md-4 p-5" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                    <div className='border p-4 meaning-card'  style={{padding:"2rem"}}  >
                        <h3 className="text-center" style={{color:"rgb(255,102,0)"}}>KnowledgeView™</h3>
                        <p className="text-center">Forecasts equipment and process behaviour, addressing "What could happen?" and Offers real-time solutions for "How to respond to potential future events?"</p>
                    </div>
                </div>
            </div>

            <div className="row">
                <p className="col-md-11 text-justify">
                our suite of modular building blocks, including ADISRA-THETAVEGA SmartView, InsightView™, and KnowledgeView™, along with our unwavering commitment to superior customer support, empowers businesses to navigate the complexities of data-driven decision-making, ensuring efficiency, productivity, and success in an ever-evolving landscape. With each building block, we provide the foundation for your organization's growth and innovation.
                </p>
            </div>
            

        </div>

    );
};




export default Buildingblocks;
