import React from 'react'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import "./Datacpture.css"
import logo1 from "../TRACK N TRACE/spc/banner.png";
import logo2 from "../TRACK N TRACE/spc/SPC Software Implementation.webp";
import logo3 from "../TRACK N TRACE/spc/Process Improvement Services.avif";
import logo4 from "../TRACK N TRACE/spc/Quality COntrol.avif";
import logo5 from "../TRACK N TRACE/spc/Root Cause Analysis Services.jpg";
import logo6 from "../TRACK N TRACE/spc/Statistical Process Control Training.png";
import AOS from 'aos';
import 'aos/dist/aos.css';







export const Statistical = () => {

	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  return (
    <div className="container-fluid" style={{overflow:"hidden"}}>
		<div className="bg0">
		  <p className="col-md-12" >
			<NavLink to="/" className="navbutton1"> Our Solutions </NavLink>/ <NavLink to="#" className="navbutton2"> Statistical process </NavLink>
		  </p>
		</div>
  
		<header className="">
		  <img src={logo1} alt="Logo" className='aboutimg mt-0'/>
		  <div className="container" >
			<div className="row">
			 
			</div>
		  </div>
		 
		</header>
		{/* --------------------------------------SECTION----------------------------------------------------- */}
		<div className="container dataparts">
		  <div className="row paragraph1 mt-5">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo2} alt="loading..." style={{ height: "300px", width: '80%' }} />
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b> Software Implementation</b></span>
			  </h3>
			  <p>
			  SPC software implementation services help businesses select and implement software tools that enable them to collect, analyse, and visualize process data, monitor production processes in real time, and identify areas for improvement. This includes selecting and integrating software tools, providing training and support for users, and customizing software applications as needed
			  </p>
			</div>
		  </div>
		</div>
  
		<div className="container" >
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Process Improvement Services</b></span>
			  </h3>
			  <p>
			  Process improvement services help businesses identify areas where they can improve your production processes to reduce waste, increase efficiency, and improve quality. This includes analysing process data, identifying trends and patterns, and recommending process improvements.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo3} alt="loading..."/>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo4} alt="loading..."/>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Quality Control Services</b></span>
			  </h3>
			  <p>
			  Quality control services help businesses monitor the quality of their products or services to ensure you meet customer requirements and standards. This includes developing quality control plans, collecting and analysing quality data, and implementing corrective actions.
			  </p>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-right">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Root Cause Analysis Services</b></span>
			  </h3>
			  <p>
			  Root cause analysis services help businesses identify the underlying causes of quality issues or defects in their products or services. This includes using statistical methods and data analysis tools to identify trends and patterns and recommend corrective actions.
			  </p>
			</div>
			<div className="col-md-5" data-aos="zoom-in-right">
			  <img src={logo5} alt="loading..."/>
			</div>
		  </div>
		</div>
  
		<div className="container">
		  <div className="row  paragraph1">
  
			<div className="col-md-5" data-aos="zoom-in-left">
			  <img src={logo6} alt="loading..."/>
			</div>
			<div className="col-md-5" data-aos="zoom-in-left">
			  <h3 className="b11">
				<span style={{ color: "rgb(255,102,0)" }}><b>Statistical Process Control Training</b></span>
			  </h3>
			  <p>
			  Statistical process control training services help businesses train their employees on the principles and techniques of SPC analytics, enabling them to monitor and improve production processes independently. This includes developing training materials and delivering training programs.
			  </p>
			</div>
		  </div>
		</div>
	  
		<div className="container">
		  <div className="row  paragraph1">
			<div className="col-md-10 mt-5" data-aos="zoom-in-left">
			  <p>
			  Collectively we provide businesses with the tools and expertise they need to monitor and improve the quality of their products or services, reducing waste, increasing efficiency, and improving customer satisfaction.<NavLink to="/contact"  > Get in touch with us for more Details.</NavLink>
			  </p>
			</div>
		  </div>
      <NavLink to="/dataintegration" className="" style={{float:"right",color:"rgb(255,102,0)"}} data-aos="zoom-in-left"><p>Explore to Data Integration</p>
		</NavLink>
    </div>
  
	  </div>
  )
}

export default Statistical
