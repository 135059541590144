import React from 'react';
import { NavLink } from 'react-router-dom';
import "./Datacpture.css"
import logo1 from '../TRACK N TRACE/MACHINE 2 MACHINE/Banner.png'
import logo2 from '../TRACK N TRACE/MACHINE 2 MACHINE/M2M-Connectivity-Services.jpg'
import logo3 from '../TRACK N TRACE/MACHINE 2 MACHINE/M2M-Platform-Development.png'
import logo4 from '../TRACK N TRACE/MACHINE 2 MACHINE/M2M-Application-Development.png'
import logo5 from '../TRACK N TRACE/MACHINE 2 MACHINE/M2M-Data-Analytics.avif'
import logo6 from '../TRACK N TRACE/MACHINE 2 MACHINE/M2M-Security-Solutions.webp'
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const M2M = () => {

	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
  }, []);

  return (
	<div className="container-fluid" style={{overflow:"hidden"}}>
	<div className="bg0">
	  <p className="col-md-12" >
		<NavLink to="/" className="navbutton1"> Dashboards </NavLink>/ <NavLink to="#" className="navbutton2"> Machine To Machine </NavLink>
	  </p>
	</div>

	<header className="">
	  <img src={logo1} alt="Logo" className='aboutimg mt-0'/>
	  <div className="container" >
			<div className="row">
			  <div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
				<p>
				  <span style={{ color: "rgb(255,102,0)" }}>Machine-to-machine (M2M)  </span>communication enables devices and machines to exchange data and information automatically without the need for human intervention. Here are some of our common offerings for Machine to Machine Communication systems:


				</p>
			  </div>
			</div>
		  </div>
	  
	</header>
	{/* --------------------------------------SECTION----------------------------------------------------- */}
	<div className="container dataparts">
	  <div className="row paragraph1 mt-5">

		<div className="col-md-5" data-aos="zoom-in-left">
		  <img src={logo2} alt="loading..." />
		</div>
		<div className="col-md-5" data-aos="zoom-in-left">
		  <h3 className="b11">
			<span style={{ color: "rgb(255,102,0)" }}><b>Connectivity Services</b></span>
		  </h3>
		  <p>
		  M2M connectivity services provide businesses with the ability to connect devices and machines to a network, enabling them to exchange data and information with other devices and systems. This includes selecting the right connectivity technologies and protocols, configuring network settings, and managing connectivity.
		  </p>
		</div>
	  </div>
	</div>

	<div className="container">
	  <div className="row  paragraph1">

		<div className="col-md-5" data-aos="zoom-in-right">
		  <h3 className="b11">
			<span style={{ color: "rgb(255,102,0)" }}><b>Platform Development</b></span>
		  </h3>
		  <p>
		  M2M platform development services help businesses create custom M2M platforms that enable them to monitor and control their devices and machines remotely. This includes developing software platforms, selecting and integrating hardware components, and providing ongoing maintenance and support.
		  </p>
		</div>
		<div className="col-md-5" data-aos="zoom-in-right">
		  <img src={logo3} alt="loading..."/>
		</div>
	  </div>
	</div>

	<div className="container">
	  <div className="row paragraph1">

		<div className="col-md-5" data-aos="zoom-in-left">
		  <img src={logo4} alt="loading..."/>
		</div>
		<div className="col-md-5" data-aos="zoom-in-left">
		  <h3 className="b11">
			<span style={{ color: "rgb(255,102,0)" }}><b>Application Development</b></span>
		  </h3>
		  <p>
		  M2M application development services help businesses create custom applications that enable them to access and analyse data from their devices and machines. This includes developing software applications, integrating them with existing systems, and providing ongoing maintenance and support.
		  </p>
		</div>
	  </div>
	</div>

	<div className="container">
	  <div className="row  paragraph1">

		<div className="col-md-5" data-aos="zoom-in-right">
		  <h3 className="b11">
			<span style={{ color: "rgb(255,102,0)" }}><b>Data Analytics</b></span>
		  </h3>
		  <p>
		  M2M data analytics services help businesses analyse the data collected from their devices and machines to gain insights and make data-driven decisions. This includes developing custom analytics tools and dashboards, integrating data from multiple sources, and providing ongoing maintenance and support.
		  </p>
		</div>
		<div className="col-md-5" data-aos="zoom-in-right">
		  <img src={logo5} alt="loading..."/>
		</div>
	  </div>
	</div>

	<div className="container">
	  <div className="row  paragraph1">

		<div className="col-md-5" data-aos="zoom-in-left">
		  <img src={logo6} alt="loading..." />
		</div>
		<div className="col-md-5" data-aos="zoom-in-left">
		  <h3 className="b11">
			<span style={{ color: "rgb(255,102,0)" }}><b>Security Solutions</b></span>
		  </h3>
		  <p>
		  M2M security solutions help businesses secure their M2M systems and prevent unauthorized access or data breaches. This includes implementing encryption and authentication protocols, monitoring network activity, and providing ongoing maintenance and support.
		  </p>
		</div>
	  </div>
	</div>
	

	<div className="container">
	  <div className="row  paragraph1">
		<div className="col-md-10 mt-5" data-aos="zoom-in-left">
		  <p>
		  Collectively we provide businesses with the tools and expertise you need to connect, manage, and analyse your devices and machines remotely, improving operational efficiency, reducing costs, and enhancing the customer experience.<NavLink to="/contact"  > Get in touch with us for more Details.</NavLink>
		  </p>
		</div>
	  </div>
	  <NavLink to="/remotemonitoring" className="" style={{float:"right",color:"rgb(255,102,0)"}} data-aos="zoom-in-left"><p>Explore Remote Monitoring</p>
		</NavLink>
	</div>

  </div>
  );
}

export default M2M;
