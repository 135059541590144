import React from "react";
import '../About.css'
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import banner from '../Industries/TEXTILE/banner.png'
import energyeffi from '../Industries/TEXTILE/energyefficient.jpg'
import inventary from '../Industries/TEXTILE/inventarymgmt.avif'
import predictive from '../Industries/TEXTILE/predictivemain.avif'
import productionline from '../Industries/TEXTILE/productionline.jpg'
import quality from '../Industries/TEXTILE/qualitycontrol.jpg'
import realtime from '../Industries/TEXTILE/realtimemoniter.jpeg'
import supplychain from '../Industries/TEXTILE/supplychaintranc.jpg'




const Textile = () => {
	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page when the component mounts
	}, []);

	
	useEffect(() => {
		AOS.init(); // Initialize the AOS library
	  }, []);

	return (
		<div className="container-fluid" style={{overflow:"hidden"}}>
		

			<header className="" style={{marginTop:"0rem"}}>
				<img src={banner} alt="Logo" className='aboutimg' />
				
				<div className="container">
					<div className="row">
						<div className="col-md-11 paragraph1" style={{ marginTop: "1rem" }}>
							<p>
								<span style={{ color: "rgb(255,102,0)" }}></span>In the textile manufacturing industry, our Automation, Digitization and Traceability solutions are tailored to enhance production efficiency, product quality, and sustainability. We offer a comprehensive suite of solutions designed to address the specific needs of textile manufacturing:
							</p>
						</div>
					</div>
				</div>
			</header>
			{/* --------------------------------------SECTION----------------------------------------------------- */}
			<div className="container dataparts">
				<div className="row paragraph1 mt-5">
					
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={productionline} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }} >
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Smart Production Lines</b></span>
					</h3>
						<p>
						Automate various stages of textile manufacturing, from weaving and knitting to dyeing and finishing. Our solutions optimize processes, reduce manual intervention, and improve overall productivity.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
					
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Quality Control and Inspection</b></span>
					</h3>
						<p>
						Implement automated quality control systems using computer vision and imaging technologies. Detect defects, inconsistencies, and variations in fabrics, ensuring only high-quality products reach the market.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={quality} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={inventary} alt="loading..."  />
					</div>
					<div className="col-md-5"  data-aos="zoom-in-right" style={{ margin: "auto" }}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Inventory Management</b></span>
					</h3>
						<p>
						Digitize inventory tracking and management of raw materials and finished goods. Real-time data on material levels and product availability enable efficient planning and order fulfilment.

						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5"  data-aos="zoom-in-left" style={{ margin: "auto" }}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Predictive Maintenance</b></span>
					</h3>
						<p>
						Utilize sensors and analytics to predict maintenance needs for machinery and equipment. Prevent costly downtime by identifying potential issues before they escalate.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={predictive} alt="loading..." />
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={energyeffi} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Energy Efficiency Solutions</b></span>
					</h3>
						<p>
						Optimize energy consumption within textile manufacturing processes. Monitor energy usage, identify inefficiencies, and implement strategies to reduce environmental impact and operational costs.
						</p>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
					<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Supply Chain Transparency</b></span>
					</h3>
						<p>
						Implement traceability solutions to track the journey of raw materials, components, and finished products. Enhance transparency, meet compliance requirements, and ensure sustainability practices.
						</p>
					</div>
					<div className="col-md-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<img src={supplychain} alt="loading..."/>
					</div>
				</div>
			</div>

			<div className="container">
				<div className="row  paragraph1">
				
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<img src={realtime} alt="loading..."/>
					</div>
					<div className="col-md-5" data-aos="zoom-in-right" style={{ margin: "auto" }}>
						<br />
						<h3 className="b11">
						<span style={{ color: "rgb(255,102,0)" }}><b>Real-time Monitoring and Reporting</b></span>
					</h3>
						<p>
						Utilize IoT sensors and real-time data analytics to monitor production processes, machine performance, and product quality. Generate reports for informed decision-making and process optimization.
						</p>
					</div>
				</div>
			</div>
					
			<div className="container">
				<div className="row  paragraph1">
					<div className="col-md-11 mt-5" data-aos="zoom-in-left" style={{ margin: "auto" }}>
						<p>
						At Thetavega, we understand the complexities of textile manufacturing. Our Automation, Digitization and Traceability solutions are designed to elevate manufacturing capabilities, from efficient production to sustainable practices. Join us in shaping a more efficient, high-quality, and environmentally conscious future for the textile industry.
						</p>
					</div>
				</div>
				{/* <NavLink to="/industries/pharmaceuticals" className="" style={{float:"right",color:"rgb(255,102,0)",fontSize:"1.2rem", marginTop:"2rem",marginRight:"5rem" }} data-aos="zoom-in-left">Explore to Pharmaceuticals
		</NavLink> */}
			</div>

		</div>
	);
};


export default Textile
